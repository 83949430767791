/* Outside libraries/packages/plugins  
	***********************************/
import React from 'react';
import ReactDOM from 'react-dom';
import "./index.css";
import App from "./App";
import registerServiceWorker from './registerServiceWorker';
import ReactGA from "react-ga";
ReactGA.initialize("UA-38613491-26");
ReactGA.pageview(window.location.pathname + window.location.search);


ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWorker();
