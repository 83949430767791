import React, {
  Component,
  useDebugValue
} from "react";
import {
  debug
} from "util";
import {
  EALREADY
} from "constants";
import _ from 'lodash';
//setup before functions
var typingTimer; //timer identifier
var doneTypingInterval = 500; //time in ms, 5 second for example
export default class ContentEditable_v1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      isDirty: false,
      editable: true,
      showMessage: false
    };
    //this.changeHours = this.changeHours.bind(this);
    //this.doneTyping = this.doneTyping.bind(this);
    this.delayedHourSave = _.debounce(this.SaveHours, 500);
  }

  // handleChange = e => {
  //   if (e.target.value <= 60) {
  //     this.setState({
  //       value: e.target.value
  //     });
  //    //this.SaveHours(e);
  //   }

  // }
  // changeHours(target) {
  //   console.log(target, "targetValue");
  //   this.SaveHours(target);
  // }
  handleChange = e => {

    var values = e.target.value;
    const target = e;

    if (e.target.value.split('.')[1] > 99) {
      return;
    }
    if (e.target.value.indexOf(".") == 0) {
      values = "0.";
    }
    var that = this;
    if (values == "") {
      this.setState({
        value: values
      }, function (e) {
        setTimeout(that.doneTyping, doneTypingInterval);
      });
    }
    // //console.log(e.target.value.indexOf("."),"decimal");
    // if (parseFloat(values) <= 60) {
    //   //this.props.onrowchange(this.props.row, this.props.roomId,this.props.hrtype,values);
    //   this.setState({
    //     value: values
    //   });
    //   //this.SaveHours(e);
    // }
    //+"_"+this.props.row.AssNum
    //console.log(this.props,"aaaaaaaaaaaaaaa");

    if (parseFloat(values) <= 60) {
      this.props.onrowchange(this.props.row, this.props.roomId, this.props.hrtype, values);
      this.setState({
        value: values,
        isDirty: true,
      }, function (e) {
        if (that.state.isDirty) {
          that.setState({
            isDirty: false
          });
          clearTimeout(typingTimer);
          typingTimer = setTimeout(that.doneTyping, doneTypingInterval);
        }
      });
    }
  }

  //user is "finished typing," do something
  doneTyping = (e) => {
    //console.log(props.row)
    let el = document.querySelector(':focus');
    if (el) el.blur();
    el.focus();
  }
  SaveHours = e => {
    //console.log(e.target.name, "oldvalue");
    //console.log(e.target.value, "newvalue");

    let oldValue = e.target.name;
    let newValue = e.target.value;
    if (oldValue != newValue) {
      this.props.save(e, this.props.row, this.props.hrtype);
    }

  };

  shouldComponentUpdate(nextProps, nextState) {
    var props = this.props;
    var shouldUpdate = false;
    // if (props === nextProps) shouldUpdate = false;
    if (nextState.value != this.state.value || nextState.showMessage != this.state.showMessage || nextState.value === 0) shouldUpdate = true;
    return shouldUpdate;
  }

  processProps = props => {

    var row = props.row;
    // if(row.FName=="Frank")
    //     debugger;
    var hrtype = props.hrtype;
    var showMessage = false;
    // First, get the hour values
    // if(row.FName=="Jacob")
    // //console.log(row,"rows_data");
    // if (row.FName == "Joe") debugger;
    let value = "";
    let empid = "";
    let editable = true;
    empid = row.EmpID + "_" + row.FName + "_" + hrtype;
    if (row.Hours != null) {
      if (row.Hours.length > 0) {
        switch (hrtype) {
          case "RT":
            value = row.Hours[0].RTHours;
            break;
          case "OT":
            value = row.Hours[0].OTHours;
            break;
          case "DT":
            value = row.Hours[0].DTHours;
            break;
          case "TT":
            value = row.Hours[0].TTHours;
            break;
        }

        if (row.Hours[0].RTHours != "" || row.Hours[0].OTHours) {
          //console.log(row.Hours[0].EnteredByEmail, "eneterdbyemail");
          //console.log(sessionStorage.getItem("email"), "sessionemail");
          if (row.Hours[0].EnteredByEmail != undefined) {
            if ((sessionStorage.getItem("email") != row.Hours[0].EnteredByEmail) && (typeof row.Hours[0].RTHours != "undefined" || typeof  row.Hours[0].OTHours != "undefined")) {             
              document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_RT" + "_" + row.AssNum).setAttribute("disabled", false);
              document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_OT" + "_" + row.AssNum).setAttribute("disabled", false);
              
            }

          } else {

            let x = document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_" + row.AssNum);
            console.log(x);
            if (x != null) {
              x.classList.remove('disabled');
            }


            //document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName  +"_"+ row.AssNum).classList.remove("disabled");
            document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_RT" + "_" + row.AssNum).removeAttribute("disabled");
            document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_OT" + "_" + row.AssNum).removeAttribute("disabled");
          }

        }

        if (typeof row.Hours[0].Finalized == "undefined" || row.Hours[0].Finalized == "1900-01-01") {
          editable = true;
        }
        // And now check to see if time is finalized
        else {
          editable = false;
        }
      }
    }
    // if (row.CompBranchID != row.OrderBranchID) {
    //   showMessage = true;
    //   editable = false;
    // }

    if (row.CompBranchID != row.OrderBranchID) {
      if (row.OrderBranchID == this.props.selectdBranchId) {
        showMessage = false;

        if (row.Hours.length > 0) {
          if (typeof row.Hours[0].Finalized == "undefined" || row.Hours[0].Finalized == "1900-01-01") {
            editable = true;
          }
          // And now check to see if time is finalized
          else {
            editable = false;
          }
        }
        // editable = true;
      } else {
        showMessage = false;
        editable = true;
      }
    }



    // if (row.CompBranchID != row.OrderBranchID && row.OrderBranchID==this.props.selectdBranchId) {
    //   showMessage = false;
    //   editable = true;
    // }

    //console.log(this.props.selectdBranchId, "selectdBranchId");
    //console.log(empid, "empid");
    this.setState({
      id: empid,
      value: value,
      editable: editable,
      showMessage: showMessage
    });
  };
  componentDidMount() {
    this.processProps(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.processProps(nextProps);
  }

  render() {
    // debugger;
    // This will render the actual input field
    if (this.state.editable === true) {
      return (<div>
        <input id={
          this.props.row.EmpID + "_" + this.props.row.FName + "_" + this.props.row.LName + "_" + this.props.hrtype + "_" + this.props.row.AssNum
        }
          name={
            this.props.row.Hours.length > 0 ? (this.props.hrtype == "RT" ? this.props.row.Hours[0].RTHours : this.props.row.Hours[0].OTHours) : ''
          }
          value={
            this.state.value
          }
          className={
            "hours-input"
          }
          onChange={
            this.handleChange
          }
          onBlur={
            this.SaveHours
          }
          onKeyPress={
            e => {
              if ((e.charCode < 48 || e.charCode > 57) && e.charCode !== 46) {
                e.preventDefault();
              }
              if (e.target.value.indexOf(".") > -1 && (e.target.value.split('.')[1].length > 1)) {
                if (e.target.value.split('.')[1].length > 2) {
                  e.preventDefault();
                }
              }
            }
          }
        />
        {/* Comment This Line */}
        {/* <span id={"span_" + this.props.row.EmpID + "_" + this.props.row.FName + "_" + this.props.row.LName +  "_" + this.props.row.AssNum
          }
          className="socket-span">
          </span> */}
      </div >
      );
    } else {
      if (this.state.showMessage === true) {
        return <div> ⓘ </div>
      } else {
        return <div> {
          this.state.value
        } </div>;
      }
    }
  }
}