/* Outside libraries/packages/plugins  
***********************************/
import React from "react";
import axios from "axios";
import Panel from "react-bootstrap/lib/Panel";
import { GoogleLogin } from "react-google-login";
import { GoogleLogout } from "react-google-login";
import Moment from "moment";

/* Components/CSS that we have made       
***********************************/
import Header from "./Header";
import FilterForm from "./FilterForm";
import DataGrid from "../MainContent/DataGrid";
// import Meter from "./Meter";
import "./Hero.css";
import "../Sidebar/Sidebar.css";
import SummaryTable from "../MainContent/SummaryTable";
import LogRocket from 'logrocket';
// //console.log(sessionStorage.getItem("email"));
/* Hero class starts here
***********************************/
export default class Hero extends React.Component {
  constructor(props, context) {
    super(props, context);
    let openState = true;
    if (this.props.branch && this.props.WEDate) {
      openState = false;
    }
    let dbWEDate = "";
    if (this.props.WEDate) {
      dbWEDate = Moment(this.props.WEDate).format("YYYY-MM-DD");
    }

    this.state = {
      open: openState,
      branches: [],
      filterFormValuesArray: "",
      selectedBranch: this.props.branch,
      selectedBranchName: "",
      selectedUDFID: this.props.UDFID,
      selectedWEDate: this.props.WEDate,
      databaseWEDate: dbWEDate,
      isHovered: false,
      showCancelButton: false,
      email: "",
      token: "",
      SuperUser: "",
      WFSUser: "",
      r1: 0.7,
      r2: 0.3,
      isHome: true,
      isSummary: false,
      summaryData: []
    };
    // //console.log('line 39' )

    // Pull user from sessionStorage
    const userEmail = sessionStorage.getItem("email");
    if (userEmail) {
      const userToken = sessionStorage.getItem("token");
      this.state.email = userEmail;
      this.state.token = userToken;
      this.loadBranches();
    }

    // Declares this to actually mean ...this!
    this.filterFormValues = this.filterFormValues.bind(this);
    this.filterFormReset = this.filterFormReset.bind(this);
    this.handleSetPage = this.handleSetPage.bind(this);
    this.loadBatchFiles = this.loadBatchFiles.bind(this);
    const self = this;
    // And finally let's get the branch name for later
    if (this.props.branch !== undefined) {
          axios
            .get(`/api/FindBranch/${this.props.branch}`, { responseType: "json" })
            .then(resp => {
              //console.log(resp);
              if (resp.data.length > 0) {
               let newSelectedValues = [
                 {
                   open: false,
                   branchid: self.props.branch,
                   UDFID: self.props.UDFID,
                   branchName: resp.data[0].BranchName,
                   wedate: self.props.WEDate
                 }
               ];
               self.setState({
                 selectedBranchName: resp.data[0].BranchName,
                 filterFormValuesArray: newSelectedValues
               });
              }
            });
    }
  }
  
  componentWillMount() {
     const email = sessionStorage.getItem("email");
     if (email !== null) {
          axios
            .get(`/api/CheckUserStatus/${email}`)
            .then(resp => {
              this.setState({
                SuperUser: resp.data.SuperUser,
                WFSUser: resp.data.WFSUser
              });
            })
            .catch(console.error);
     }
     
     const branch = this.props.branch;
     const self = this;
     //const email = sessionStorage.getItem("email");
     if ((branch !== undefined) && (email !== null)) {
          ////console.log('check for access');
          axios
            .get(`/api/CheckBranchAccess/${email}/${branch}`)
            .then(resp => {
               ////console.log(resp.data);
               if (resp.data) {
                    if (!self.state.isUnMounted) {
                         self.setState({
                              filterFormValuesArray: [],
                              open: true,
                              selectedBranch: "",
                              selectedBranchName: "",
                              selectedUDFID: "",
                              selectedWEDate: ""
                         });
                         sessionStorage.removeItem("selectedBranch");
                         sessionStorage.removeItem("selectedBranchName");
                         sessionStorage.removeItem("selectedUDFID");
                         sessionStorage.removeItem("selectedWEDate");
                    }
               }
            })
            .catch(console.error);
     } else {
          ////console.log('check not needed');
     }

  }

  // using this.isUnMounted gets rid of the 'Can't call setState (or forceUpdate) on an unmounted component' error
  componentDidUpdate() {
    // //console.log(this.state);
  }

  componentDidMount() {
    this.isUnMounted = false;
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  // animate() {
  //   this.setState({
  //     r1: Math.random(),
  //     r2: Math.random()
  //   });
  // }

  // function to show/hide edit button on hover
  handleHover() {
    this.setState({
      isHovered: !this.state.isHovered
    });
  }

     loadBatchFiles() {
          //console.log("test");
          axios
               .get(`/api/BatchFile/${this.state.selectedBranch}`)
               .then(resp => {
                    //console.log(resp);
                    this.setState({
                         summaryData: resp.data,
                    });
                    sessionStorage.setItem("selectedBranchName", this.state.selectedBranchName);
               })
               .catch(console.error);
     }


     filterFormValues (selectedValues) {
          var databits = selectedValues[0].wedate.split("/");
          var newwedate = databits[2] + "-" + databits[0] + "-" + databits[1];
          //console.log("selected values");
          //console.log(selectedValues[0]);
          this.setState({
               open: false,
               filterFormValuesArray: selectedValues,
               selectedBranch: selectedValues[0].branchid,
               selectedBranchName: selectedValues[0].branchName,
               selectedUDFID: selectedValues[0].UDFID,
               selectedWEDate: selectedValues[0].wedate,
               databaseWEDate: newwedate,
               showCancelButton: true
          }, () => {
               this.loadBatchFiles();
          }); // close the panel and show the table
     } // end function filterFormValues

  filterFormReset() {
    this.setState({
      open: false
    });
  }

  
  
  
  // function to get the branches the user has access to
  loadBranches() {
    // add an if statement to check for email
    // //console.log(this.state);
    axios
      .get(`/api/UsersBranches/${this.state.email}`)
      .then(resp => {
        this.setState({
          branches: resp.data,
          invalid: resp.data == ""
        });
      })
      .catch(console.error);
  } // end function loadBranches

  handleSetPage(page) {
    this.setState({
       isHome: page === 'home',
       isSummary: page === 'summary',
        filterFormValuesArray: [],
        open: true,
        selectedBranch: undefined,
        selectedUDFID: "",
        selectedBranchName: "",
        selectedWEDate: ""
    });

      sessionStorage.removeItem("selectedBranch");
      sessionStorage.removeItem("selectedBranchName");
      sessionStorage.removeItem("selectedUDFID");
      sessionStorage.removeItem("selectedWEDate");
  }

  render() {
    
    const responseGoogle = response => {
      this.setState({
        email: response.w3.U3,
        token: response.w3.Eea
      });
      sessionStorage.setItem("email", response.w3.U3);
      sessionStorage.setItem("token", response.w3.Eea);
      this.loadBranches();
      LogRocket.init('jzexde/payroll-board');
      var email_detail=sessionStorage.getItem("email");
      console.log(email_detail,"email_detail");
      LogRocket.identify(email_detail, {
        name: email_detail,
        email: email_detail,
        // Add your own custom user variables here, ie:
        subscriptionType: 'pro'
      });
    };

    const logout = () => {
      this.setState({
        email: "",
        token: "",
        selectedBranch: undefined,
        selectedBranchName: "",
        selectedUDFID: "",
        selectedWEDate: ""
      });
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("selectedBranch");
      sessionStorage.removeItem("selectedBranchName");
      sessionStorage.removeItem("selectedUDFID");
      sessionStorage.removeItem("selectedWEDate");
      window.location = "/";
    };

    // conditional to render login button if user is not signed in, otherwise hide button
    // const isAuth = this.state.token ? "hide" : "primaryBtn";
    const loggedIn = this.state.token ? "hide" : "welcome heading1";
    const logoutBtn = this.state.token ? "logoutBtn" : "hide";
    // conditional to render error message if users email credentials do not match
    const invalidError = this.state.invalid ? "error" : "hide";
    // Check the user status to determine if we see the settings button
    const email = sessionStorage.getItem("email");

    if (!this.state.open && !this.state.isUnMounted) {
      return (
        <div>
          <div
            className="heroExpand btnClass"
            onClick={() =>
              this.setState({
                open: !this.state.open
              })
            }
          >
            <div className="heroExpandContainer">
              <Header
                userSelectedBranch={this.state.selectedBranch}
                userSelectedBranchName={this.state.selectedBranchName}
                userSelectedUDFID={this.state.selectedUDFID}
                userSelectedWEDate={this.state.selectedWEDate}
                showEditButtonOnHover={this.state.open}
                isSummary={this.state.isSummary}
              />
            </div>

            <Panel
              id="collapsible-panel-example-1"
              expanded={this.state.open}
              onToggle={() => {}}
            >
              <Panel.Collapse>
                <Panel.Body />
              </Panel.Collapse>
            </Panel>
          </div>
          {/*<Meter percent={0.4} width={250} height={17} rounded={false} />*/}

          {/* end heroExpand */}
          <div className="mainContent">
               <div className="mainContent_card">
                    {this.state.isHome && this.state.filterFormValuesArray.length > 0 && (
                         <DataGrid
                              userSelectedBranch={this.state.selectedBranch}
                              userSelectedUDFID={this.state.selectedUDFID}
                              userSelectedWEDate={this.state.databaseWEDate}
                         />
                    )}
                    {this.state.isSummary && (
                         <SummaryTable
                              BranchID={this.state.selectedBranch}
                              BranchName={this.state.selectedBranchName}
                              UDFID={this.state.selectedUDFID}
                              summaryData={this.state.summaryData}
                         />
                    )}
               </div>
          </div>

          {/* end mainContent */}
        </div>
      ); // empty React div // end return
    } else {
      // render if user has not chosen branch/we dates
      const settingsBtnClass = this.state.SuperUser ? "settingsBtn" : "hide";
      // //console.log(settingsBtnClass);

      return (
        <div>
          <div className="heroExpandContainer">
            <div className="heroExpand">
              <div className="login">
                <div className={invalidError}>
                  <div className="loginError">
                    <p className="error">
                      You do not have permission to view this application. If
                      you think you should have access, <br />
                      please make sure you are logging in with your Onin email
                      address.
                    </p>
                    <div className="error">Still having trouble?</div>
                  </div>
                  <a
                    className="btnContainer"
                    href="mailto:support@oninstaffing.com"
                  >
                    <button className="secondaryBtn">
                      Contact
                      <br />
                      Support
                    </button>
                  </a>
                </div>
                <div className={loggedIn}>
                  Welcome to the Payroll Board
                  <GoogleLogin
                    clientId="1067828095624-4ga9k7vilr6r9ioanlmign2pgnf7f0ir.apps.googleusercontent.com"
                    className="primaryBtn marginTop"
                    buttonText="Log In"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                  />
                  
                </div>

                <div className={logoutBtn}>
                    <span className="formatting" onClick={() => this.handleSetPage('home')}>Home</span>
                    <span className="formatting" onClick={() => this.handleSetPage('summary')}>Summary</span>
                  <div className={settingsBtnClass}>
                    <a href="/settings">Admin Settings</a>
                  </div>

                  <GoogleLogout
                    className="formatting"
                    buttonText="Log Out"
                    onLogoutSuccess={logout}
                  />
                  <span className="ver">Version 1.8</span>
                </div>
              </div>
              <div>
                <Panel
                  id="collapsible-panel-example-1"
                  expanded={this.state.open}
                  onToggle={() => {}}
                >
                  <Panel.Collapse>
                    <Panel.Body>
                      {this.state.branches.length > 0 && (
                        <FilterForm
                          callbackFilterFormValues={this.filterFormValues}
                          callbackFilterFormReset={this.filterFormReset}
                          branchesFromHero={this.state.branches}
                          showCancelButton={
                            this.state.showCancelButton // these are all the branches the user has permission to see
                          }
                          userSelectedBranch={this.state.selectedBranch}
                          userSelectedBranchName={this.state.selectedBranchName}
                          userSelectedUDFID={this.state.selectedUDFID}
                          userSelectedWEDate={this.state.selectedWEDate}
                          isSummary={this.state.isSummary}
                        />
                      )}
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </div>
            </div>
            <div className="mainContent" />
          </div>
        </div>
      ); // end return
    }
  }
}
Hero.propTypes = {};
