/* Components/CSS that we have added      
***********************************/
import React from "react";
import axios from "axios";
import Moment from "moment";
import mongoose from "mongoose";
// import ContentEditable from "./ReactContentEditable";
import ContentEditable_v1 from "./ContentEditable_v1";
import RequestQueue from "./RequestQueue";
import { Redirect } from 'react-router-dom';
import PayAdjustmentModal from "./PayAdjustmentModal";
import {
    DataTypeProvider,
    GroupingState,
    SearchState,
    IntegratedGrouping,
    FilteringState,
    IntegratedFiltering
} from "@devexpress/dx-react-grid";
import { RowDetailState } from "@devexpress/dx-react-grid";
import // State or Local Processing Plugins
    "@devexpress/dx-react-grid";
import {
    Grid,
    VirtualTable,
    Table,
    TableBandHeader,
    TableHeaderRow,
    TableRowDetail,
    TableColumnVisibility,
    TableGroupRow,
    Toolbar,
    SearchPanel
} from "@devexpress/dx-react-grid-bootstrap3";

// import {

//     TableRowDetail
// } from "@devexpress/dx-react-grid-material-ui";

import {
    Template,
    TemplatePlaceholder,
    Plugin
} from "@devexpress/dx-react-core";
import produce from "immer";
import SnatchModal from "./SnatchModal";
import socketIOClient from 'socket.io-client'
//import packageJson from '../../package.json';
import _ from 'lodash';
/* Components/CSS that we have made       
***********************************/
import PayAdjustment from "./PayAdjustment";
import "./MainContent.css";
import SaveButton from "./SaveButton";
import {GetMode} from '../Config/config';

const socket = socketIOClient(GetMode());
//const socket = socketIOClient("http://localhost:5000");
//const socket = socketIOClient("https://prbtest.oninapps.com/api/");
const requestQueue = new RequestQueue();

var searchVal = "";

const CurrencyFormatter = ({ value }) => {
    if (value === undefined) {
        return "";
    }
    return "$" + value.toFixed(2);
};

const CurrencyTypeProvider = props => {
    return <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />;
};

// This function will either print the end date or return Filled
const EndDateFormatter = ({ value }) => {
    if (value !== "12/31/1899") {
        return value;
    } else {
        return "Filled";
    }
};

// The DataTypeProvider that uses the EndDateFormatter
const EndDateTypeProvider = props => {
    return <DataTypeProvider formatterComponent={EndDateFormatter} {...props} />;
};

// This function is called whenever an input box loses focus, causing the value within to save
//    self - points towards the datagrid object, specifically used to access the state
//    inputfield - pull the inputed value
//    row - row information so we have the information about the teammate
//    hrtype - What type of hour to save (Regular, Overtime, Double, Triple)
function saveHours(self, inputfield, row, hrtype) {
    // debugger;
    let HoursValue = inputfield.value;

    // First, see if the new input is blank
    //DO NOT COMMENT THIS CODE BELOW. IT HELPS MAKE SURE USER IS ABLE TO REMOVE TIME PREVIOUSLY ENTERED IN INPUT
    if (HoursValue === "") {
        // So the whole idea I see here is that if the submitted value is blank, we check to see
        // if there was a previous entry.  If there wasn't a previous entry then we don't have to
        // do anything, otherwise we send an undefined value into the save data

        // If there are currently no hours, just return
        if (row.Hours.length === 0) { return; }

        // We should have hours now, time to see what that value is
        let prevtime = "";
        if (hrtype === "RT") { prevtime = row.Hours[0].RTHours; }
        if (hrtype === "OT") { prevtime = row.Hours[0].OTHours; }
        if (hrtype === "DT") { prevtime = row.Hours[0].DTHours; }
        if (hrtype === "TT") { prevtime = row.Hours[0].TTHours; }
        // Now if the previous value is blank, we're not clearing anything out so return
        if (prevtime === "") { return; }
        // We are clearing time so set the value to null
        HoursValue = undefined;
    }

    // So at this point we are saving something to SavedTime
    let HourID = '';
    // Get the hour row id (using the first entry in case somehow multiple hour entries are found)
    if (row.Hours.length > 0) {
        HourID = row.Hours[0]._id;
        if (hrtype === "RT") { row.Hours[0].RTHours = HoursValue }
        if (hrtype === "OT") { row.Hours[0].OTHours = HoursValue }
        if (hrtype === "DT") { row.Hours[0].DTHours = HoursValue }
        if (hrtype === "TT") { row.Hours[0].TTHours = HoursValue }

    }
    // Create a savedata object to send to the server
    //console.log(self,"selfflflfllflf");
    var savedata = {
        DBID: HourID || "",
        SSDumpID: mongoose.Types.ObjectId(row.id),
        //WeDate: self.props.userSelectedWEDate,
        WeDate: self.props === undefined ? self.WeDate : self.props.userSelectedWEDate,
        AssNum: row.AssNum,
        EmpID: row.EmpID,
        OrderBranchID: row.OrderBranchID,
        EnteredByEmail: sessionStorage.getItem("email"),
        HoursType: hrtype,
        HoursValue: HoursValue,
        row: row
    };
    //console.log(savedata.HoursValue, "savedata");
    //requestQueue.push(savedata, self, hoursMerge);
    if (savedata.HoursValue != "") {
        requestQueue.push(savedata, self, hoursMerge);
    }

}

// Check to see if hours have been changed while saving data for the same row
//    prev - previous time entry, could be undefined if this is the first save on this row
//    curr - current time entry, what's being saved
const hoursMerge = (prev, curr) => {
    function hoursOverwrittenPredicate(a, b) {
        return (a !== b && a === null && b !== null)
            || (a !== b && typeof a === 'undefined' && typeof b !== 'undefined')
            && !(a !== b && typeof a !== 'undefined' && typeof b === 'undefined');
    }

    let merge = { ...prev, ...curr };
    if (prev !== undefined) {
        if (hoursOverwrittenPredicate(prev.RTHours, curr.RTHours)) { merge.RTHours = prev.RTHours; }
        if (hoursOverwrittenPredicate(prev.OTHours, curr.OTHours)) { merge.OTHours = prev.OTHours; }
        if (hoursOverwrittenPredicate(prev.DTHours, curr.DTHours)) { merge.DTHours = prev.DTHours; }
        if (hoursOverwrittenPredicate(prev.TTHours, curr.TTHours)) { merge.TTHours = prev.TTHours; }
    }
    return merge;
};

// This function is used to add a class to the table rows
const CustomTableGroupRow = ({ row, ...restProps }) => {
    return <Table.Row className="GroupRow" {...restProps} />;
};

// Function to just return a new Search bar
const SearchBarModification = () => (
    <Plugin>
        <Template name="toolbarContent">
            <div>
                <div className="search_icon">
                    <i className="material-icons" id="search">
                        search
                    </i>
                </div>
                <TemplatePlaceholder />
            </div>
        </Template>
    </Plugin>
);

// Our main function and the reason why we're here
export default class DataGrid extends React.Component {
    // Builds the data grid and sets up the table
    constructor(props) {
        super(props);
        // //console.log(props);
        this.renderGroupCell = this.renderGroupCell.bind(this);
        this.toggleCompanyShowAll = this.toggleCompanyShowAll.bind(this);
        this.RowDetail = this.RowDetail.bind(this);
        this.addPayAdjustment = this.addPayAdjustment.bind(this);
        this.removePayAdjustment = this.removePayAdjustment.bind(this);
        this.changeHours = this.changeHours.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.modalActionShow = this.modalActionShow.bind(this);
        this.modalActionClose = this.modalActionClose.bind(this);
        this.renderRedirect = this.renderRedirect.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.HidePopup = this.HidePopup.bind(this);
        // this.changeSearchValue = this.changeSearchValue.bind(this);

        this.removeUser = this.removeUser.bind(this);
        this.joinUser = this.joinUser.bind(this);
        this.clearSnatchMsg = this.clearSnatchMsg.bind(this);
        this.rowchange = this.rowchange.bind(this);

        this.delayedHourSave = _.debounce(this.changeHours, 500)

        // Hide columns array
        var tempHideCols = ["Visible"];
        if (this.props.userSelectedBranch != 0) { tempHideCols.push("OrderBranchName"); }
        // This sets the state and defines our table options
        this.state = {
            loading: true,
            expandedRows: [],
            groupShowLinks: [],
            showAllTeamates: false,
            timestamp: "",
            columnBands: [
                {
                    title: "Assignment Info",
                    children: [
                        { columnName: "AssNum" },
                        { columnName: "StartDate" },
                        { columnName: "EndDate" }
                    ]
                },
                {
                    title: "Regular Time",
                    children: [
                        { columnName: "RTHours" },
                        { columnName: "REGPayRate" },
                        { columnName: "REGBillRate" }
                    ]
                },
                {
                    title: "Overtime",
                    children: [
                        { columnName: "OTHours" },
                        { columnName: "OTPayRate" },
                        { columnName: "OTBillRate" }
                    ]
                }
            ],
            tableColumnExtensions: [
                { columnName: "Company", editingEnabled: false, width: 10 },
                { columnName: "EmpName", editingEnabled: false, width: 250 },
                { columnName: "OrderBranchName", editingEnabled: false, width: 100 },
                { columnName: "EmpID", editingEnabled: false, width: 100, align: "center" },
                { columnName: "AssNum", editingEnabled: false, width: 100, align: "center" },
                { columnName: "StartDate", editingEnabled: false, width: 100, align: "center" },
                { columnName: "EndDate", editingEnabled: false, width: 100, align: "center" },
                { columnName: "RTHours", editingEnabled: true, width: 110, align: "right" },
                { columnName: "REGPayRate", editingEnabled: false, width: 80, align: "right" },
                { columnName: "REGBillRate", editingEnabled: false, width: 80, align: "right" },
                { columnName: "OTHours", editingEnabled: true, width: 120, align: "right" },
                { columnName: "OTPayRate", editingEnabled: false, width: 80, align: "right" },
                { columnName: "OTBillRate", editingEnabled: false, width: 80, align: "right" },
                // { columnName: "Info", editingEnabled: false, width: 70, align: "right" },
                { columnName: "PayAdjust", editingEnabled: false, width: 100, align: "center" },
                { columnName: "columnAdd", editingEnabled: false, width: 75, align: "center" }
            ],

            defaultHiddenColumnNames: ['Company', 'EmpName'],
            enddateColumns: ["EndDate"],
            currencyColumns: ["REGPayRate", "REGBillRate", "OTPayRate", "OTBillRate"],
            hideColumns: tempHideCols,
            groups: [],
            groupToggles: [],
            rows: [],
            showModal: false,
            showpopup: false,
            SnatchData: [],
            PayAdjustmentData: [],
            redirect: false,
            SocketRoom: "",
            SocketMsg: "Heads up @username! No, your Payroll Board didn’t get a new haircut, but if something looks different it’s because hours you previously entered were submitted by @otheruser.",
            SocketClass: "snatchMsg snatchHide",
            users: [],
            SnatchEmails: "",
            searchValue: "",
            rowchange: "",
            css: ""
        };
    }

    getColumns() {
        var columns = [
            {
                name: "Company", title: "Company", width: 0,
                getCellValue: row => this.renderCompanyTag(row)
            },
            {
                name: "EmpName", title: "EmpName", width: 0,
                getCellValue: row => this.renderName(row)
            },
            { name: "OrderBranchName", title: "Branch", width: 100 },
            { name: "EmpID", title: "EEID", width: 100 },
            { name: "AssNum", title: "Number", width: 100 },
            {
                name: "StartDate", title: "Start", width: 100,
                getCellValue: row => row.StartDate ? Moment(row.StartDate).format("MM/DD/YYYY") : ""
            },
            {
                name: "EndDate", title: "End", width: 100,
                getCellValue: row => row.EndDate && row.EndDate != "1900-01-01T06:00:00.000Z"
                    ? Moment(row.EndDate).format("MM/DD/YYYY")
                    : "Filled"
            },
            {
                name: "RTHours", title: "Hrs", width: 70,
                getCellValue: row => this.renderEditable(row, "RT")
            },
            { name: "REGPayRate", title: "Pay", width: 100 },
            { name: "REGBillRate", title: "Bill", width: 100 },
            {
                name: "OTHours", title: "Hrs", width: 70,
                getCellValue: row => this.renderEditable(row, "OT")
            },
            { name: "OTPayRate", title: "Pay", width: 100 },
            { name: "OTBillRate", title: "Bill", width: 100 },
            // { name: "Info", title: "Info", width: 20, getCellValue: row => (<a href="javascript:void(0)" tabIndex="-1" title={JSON.stringify(row)}>ⓘ</a>) },
            { name: "PayAdjust", title: "Pay Adjust", width: 100, getCellValue: row => (<a id={row.EmpID + "_" + row.FName + "_" + row.LName + "_" + row.AssNum} href="javascript:void(0)" tabIndex="-1" onClick={() => this.ShowPopup(row)} className={this.ShowHidePlusSign(row) == "hide" ? "hide" : (row.Hours.length>0?(sessionStorage.getItem("email") != row.Hours[0].EnteredByEmail? "show disabled" : "show"):"show") }><i tabIndex="-1" class="glyphicon glyphicon-plus-sign plus-sign"></i></a>) },
            // { name: "PayAdjust", title: "Pay Adjust", width: 100, getCellValue: row => (<a href="javascript:void(0)" tabIndex="-1" onClick={() => this.ShowPopup(row)}><i tabIndex="-1" class="glyphicon glyphicon-plus-sign plus-sign"></i></a>) },
            { name: "Visible", title: "Visible", getCellValue: row => (row.Visible ? "True" : "False") }
        ];
        return columns;
    }
    ShowPopup = (row) => {
        //console.log(PayAdjustmentData.RowIndex,"popupclick");
        this.setState({ PayAdjustmentData: row, showpopup: true, })
    }
    HidePopup() {
        this.setState({
            showpopup: false,
        });
    }
    ShowHidePlusSign = (row) => {
        var isShowPlusSign = "show";
        if (row.Hours[0] != undefined) {
            if (row.Hours[0].Status == "finalized") {
                isShowPlusSign = "hide";
                return isShowPlusSign;
            }
        }
        return isShowPlusSign;

    }
    // ***** Standard React Component functions  *****
    // Here we make an axios call to the server to pull the table data
    componentWillMount() {
        this.getWholePageUpdate();

    }
    getWholePageUpdate() {
        const self = this;
        axios.get(`/api/SSDump/${this.props.userSelectedBranch}/${this.props.userSelectedUDFID}/${this.props.userSelectedWEDate}`, { responseType: "json" })
            .then(resp => {
                // Store the responce in a temporary table for now
                let newTableData = resp.data;

                var dt = new Date(this.props.userSelectedWEDate);
                dt.setDate(dt.getDate() - 7); //14-4-2019

                //REMOVED DUPLICATE FILTER.. THIS IS ALREADY BEING DONE IN API

                // newTableData = _.filter(newTableData, function (o) {
                //     //debugger;
                //     var endDate = new Date(o.EndDate);
                //     var result = endDate < dt;
                //     return result;
                // });

                // debugger;
                let groupToggle = {};
                let expandedRows = [];
                let GroupShowLinks = [];
                let newGroupList = [];
                // Make a list of the groups
                if (resp.data.length > 0) {
                    let j = 0,
                        testname;
                    newGroupList.push(newTableData[0].CompID + "==" + newTableData[0].CompBranchID + "==" + newTableData[0].Company);
                    groupToggle[newTableData[0].CompID] = false;
                    for (var i = 1; i < newTableData.length; i++) {
                        testname = newTableData[i].CompID + "==" + newTableData[i].CompBranchID + "==" + newTableData[i].Company;
                        if (newGroupList[j] !== testname) {
                            j++;
                            newGroupList[j] = testname;
                            groupToggle[newTableData[i].CompID] = false;
                        }
                    }
                    // Now update the UserSelectedBranch value
                    for (var i = 0; i < newTableData.length; i++) {
                        // Set the rowIndex
                        newTableData[i].RowIndex = i;

                        // check for group show link, if null then add it
                        if (GroupShowLinks[newTableData[i].Company] == null) {
                            GroupShowLinks[newTableData[i].Company] = false;
                        }

                        // See if we need to hide this teammate
                        //console.log(this.props.userSelectedBranch);
                        if ((newTableData[i].OrderBranchID !== this.props.userSelectedBranch) && (this.props.userSelectedBranch != 0)) {
                            if (newTableData[i].Hours.length == 0 && newTableData[i].PayAdjustments.length == 0) {
                                newTableData[i].Visible = false;
                                newTableData[i].Toggleable = true;
                                GroupShowLinks[newTableData[i].Company] = true;
                            }
                            //expandedRows.push(i);
                            if (newTableData[i].PayAdjustments.length > 0) { expandedRows.push(i); }
                        } else {
                            expandedRows.push(i);
                            //if ((newTableData[i].PayAdjustments.length > 0) || (newTableData[i].Hours.length > 0 && newTableData[i].Hours[0].Status == "finalized")  ) { expandedRows.push(i); }
                        }
                    }
                }
                console.log(newTableData, "newTableData");
                // Once we have all the data we can update the state
                if (!self.isUnMounted) {
                    self.setState({
                        rows: newTableData,
                        groups: newGroupList,
                        loading: false,
                        expandedRows: expandedRows,
                        groupShowLinks: GroupShowLinks,
                        groupToggles: groupToggle,
                    });
                }
            })
            .catch(console.error);
    }
    // Once component mounts we create a room for socket.io integration
    componentDidMount() {
        this.isUnMounted = false;
        let roomID = this.props.userSelectedBranch + '_' + this.props.userSelectedUDFID + '_' + this.props.userSelectedWEDate;
        const users = [...this.state.users, sessionStorage.getItem("email")];
        this.setState({ SocketRoom: roomID, users: users });
        socket.emit('room', { room: roomID, user: sessionStorage.getItem("email") });

        socket.on('receive message', (payload) => {
            ////console.log("Got the message");
            ////console.log(payload);
            this.setState({ SocketMsg: payload.message, SocketClass: "snatchMsg" });
        });
        socket.on('new user join', (users) => {
            ////console.log("Welcome new user");
            this.joinUser(users)
        });
        socket.on('user left room', (user) => {
            ////console.log("They ran for the hills");
            this.removeUser(user)
        });
        socket.on('user row change', (inputid, row, email, inputvalue) => {
            ////{"inputId": "", "val": "", "user":""};
            var _this = this;
            setTimeout(function () { _this.getWholePageUpdate() }, 1500);

            if (document.getElementById(inputid) != null) {
                document.getElementById(inputid).value = inputvalue;
                document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_RT" + "_" + row.AssNum).setAttribute("disabled", false);
                document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_OT" + "_" + row.AssNum).setAttribute("disabled", false);
                
                //document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName +"_" + row.AssNum).setAttribute("disabled", false);
                document.getElementById('span_' + row.EmpID + "_" + row.FName + "_" + row.LName +"_" + row.AssNum).textContent = "entered by " + email;
                // document.getElementById('span_' + row.EmpID + "_" + row.FName + "_" + row.LName + "_OT"+"_"+row.AssNum).textContent ="entered by "+  email;



            }
        });
    }

    // When we leave this page we need to have the user leave the socket.io branch room
    componentWillUnmount() {
        this.isUnMounted = true;
        ////console.log("Leaving room");
        socket.emit('leave room', { room: this.state.SocketRoom, user: sessionStorage.getItem("email") });

    }
    // ***** ***** ***** ***** ***** ***** ***** *****

    // ***** Functions for generating items **** *****553
    // Generate a company tag for sorting purposes
    renderCompanyTag(row) {
        var CompID = "",
            CompBranchID = "",
            Company = "";
        if (row.CompID !== undefined) { CompID = row.CompID; }
        if (row.CompBranchID !== undefined) { CompBranchID = row.CompBranchID; }
        if (row.Company !== undefined) { Company = row.Company; }

        return CompID + "==" + CompBranchID + "==" + Company;
    }

    // This function wraps around the saveHours function and also updates the timestamp
    rowchange(row, roomId, hrtype, value) {
        socket.emit('row change', { room: roomId, inputId: row.EmpID + "_" + row.FName + "_" + row.LName + "_" + hrtype + "_" + row.AssNum, details: row, email: sessionStorage.getItem("email"), inputValue: value });
        // if (document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_" + hrtype+"_"+row.AssNum) != null) {
        //     document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_" + hrtype+"_"+row.AssNum).classList.add("hours-input-row-change");
        // }

    }


    // // Generates an input text field for the table
    renderEditable(row, hrtype) {

        return <ContentEditable_v1 save={this.changeHours} addclass="" onrowchange={this.rowchange} row={row} hrtype={hrtype} selectdBranchId={this.props.userSelectedBranch} roomId={this.props.userSelectedBranch + '_' + this.props.userSelectedUDFID + '_' + this.props.userSelectedWEDate} />
    }

    // Generates the employee name
    renderName(row) {

        var FName = "",
            LName = "";
        if (row.FName !== undefined) { FName = row.FName; }
        if (row.LName !== undefined) { LName = row.LName; }
        return FName + " " + LName;
    }

    // Renders an icon but appears to be unused?
    renderIcon(row) {
        return (
            <span>
                <i className="material-icons needhand">swap_horizontal_circle</i>
            </span>
        );
    }

    // Create the link to the exceptions page
    renderRedirect() {
        if (this.state.redirect) {
            let link = '/ExceptionsPage/' + this.props.userSelectedBranch + '/' + this.props.userSelectedUDFID + '/' + this.props.userSelectedWEDate;
            return <Redirect to={link} />
        }
    }
    // ***** ***** ***** ***** ***** ***** ***** *****

    // ***** Functions snatching modals ** ***** *****
    // This function will determine if we need to show the snatch modal or not
    modalActionShow() {
        const self = this;
        axios.get(`/api/CheckOtherEntry/${this.props.userSelectedBranch}/${this.props.userSelectedUDFID}/${this.props.userSelectedWEDate}/${sessionStorage.getItem("email")}`,
            { responseType: "json" })
            .then(resp => {
                //console.log(resp);
                if (resp.data.length != 0) {
                    // We have time to possibly be snatched
                    let emailList = [];
                    resp.data.forEach(function (item) {
                        item.Email = item.Hours[0].EnteredByEmail;
                        emailList.push(item.Hours[0].EnteredByEmail);
                    });
                    let uniquelist = emailList.filter((val, id, array) => array.indexOf(val) == id);

                    self.setState({
                        SnatchData: resp.data,
                        SnatchEmails: uniquelist.join(', '),
                        showModal: true
                    }, () => {
                        console.log(self.state);
                    });
                } else {
                    // No time to snatch, continue
                    this.setState({ redirect: true });
                }

                // turning this off until it's fixed
                //this.setState({ redirect: true });

            });
    }

    // Modal is just closing without advancing to the next stage
    modalClose() {
        this.setState({
            showModal: false,
        });
    }

    // Modal is just closing without advancing to the next stage
    modalClose() {
        this.showLoader();
        setTimeout(() => {
            this.hideLoader();
            this.setState({
                showModal: false,
            });
        }, 2000);
    }

    // Modal is closing, if merge is set then we take the time from other people
    modalActionClose(merge) {
        this.showLoader();
        if (merge) {
            //this.showLoader();
            let SnatchEmailList = [];
            this.state.SnatchData.map((data, i) => {
                SnatchEmailList[data.Hours[0].EnteredByEmail] = data.Hours[0].EnteredByEmail;
                var savedata = {
                    DBID: data.Hours[0]._id,
                    Snatcher: sessionStorage.getItem("email")
                }
                axios.post(`/api/SnatchEntry/`, savedata);
            });
            //console.log(SnatchEmailList);

            Object.keys(SnatchEmailList).forEach(function (email) {
                //console.log(email);
                axios.post(`/api/SnatchNotice/`, { ToEmail: email, FromEmail: sessionStorage.getItem("email") });
            })
            this.hideLoader();
            //console.log("test");
            //console.log("snatching time");
            socket.emit('time snatched', {
                room: this.state.SocketRoom,
                email: sessionStorage.getItem("email")
            })
        }
        setTimeout(() => {
            this.setState({
                showModal: false,
                redirect: true
            });
        }, 2000);

    }
    // ***** ***** ***** ***** ***** ***** ***** *****

    // ***** Functions for the socket.io * ***** *****
    // Will remove the user from the socket.io branch room
    removeUser(user) {
        const newUsers = Object.assign([], this.state.users);
        const indexOfUserToDelete = this.state.users.findIndex(Olduser => { return Olduser == user.user })
        newUsers.splice(indexOfUserToDelete, 1);
        this.setState({ users: newUsers })
    }

    // Will add the user to this socket.io branch room
    joinUser(user) {
        const combinedUsers = [...this.state.users, user]
        const newUsers = Array.from(new Set(combinedUsers));
        const cleanUsers = newUsers.filter(user => { return user.length > 1 })
        this.setState({ users: cleanUsers })
    }

    // This function just clears message for people whose time was snatched
    clearSnatchMsg() {
        this.setState({ SocketClass: "snatchMsg snatchHide" });
    }
    // ***** ***** ***** ***** ***** ***** ***** *****

    // ***** Functions pay adjustments *** ***** *****
    // This function will save a pay adjustment
    addPayAdjustment(payAdjProps, newState) {
        
        var temp = document.createElement('div');
        temp.innerHTML = newState.Reason;
        var payinfoReason = (temp.innerText).replace(/(<([^>]+)>)/g, "");
        
        var savedata = {
            WeDate: payAdjProps.WeDate,
            AssNum: payAdjProps.AssNum,
            EmpID: payAdjProps.EmpID,
            OrderBranchID: payAdjProps.OrderBranchID,
            Pay: newState.PayRate,
            Bill: newState.BillRate,
            PayAdjType: newState.adjustmentType,
            Code: newState.Code,
            CodeName: newState.CodeName,
            Amount: newState.Amount,
            //Reason: newState.Reason.trimEnd(),
            //Reason: newState.Reason,
            Reason: payinfoReason,
            EnteredByEmail: sessionStorage.getItem("email"),
            MeasurementValue: newState.measurementValue
        };

        var id = payAdjProps.RowIndex;
        let self = this;

        // To Show Saving PopUp
        self.showLoaderPopUp();

        // The if statement probably is useless but better safe than sorry
        // Save to the database
        var rows = this.state.rows;
        axios
            .post(`/api/PayAdjust/Add/`, savedata)
            .then(function (response) {
                let teststate = produce(self.state.rows, draft => {
                    if (draft[id].PayAdjustments == null) {
                        draft[id].PayAdjustments = [];
                    }
                    draft[id].PayAdjustments.push(response.data);
                    draft[id].Visible = true;
                    draft[id].Toggleable = false;
                });

                // To Hide Saving PopUp
                self.hideLoaderPopUp();

                self.setState({ rows: teststate, PayAdjustmentData: teststate[self.state.PayAdjustmentData.RowIndex] });

                // In case where they may not have RTHours but do have a pay adjustment - 
                // in that case, there should be a document in SavedTime with 0 for the RTHours.

                if (self.state.PayAdjustmentData.Hours.length === 0) {
                    var inputvalue = { value: "0" };
                    // debugger;
                    self.renderEditable(self.state.PayAdjustmentData.Hours.push({ 'RTHours': 0 }), "RT");
                    //self.getWholePageUpdate();
                    saveHours(payAdjProps, inputvalue, self.state.PayAdjustmentData, "RT")
                    self.getWholePageUpdate();
                }

            })
            .catch(function (error) {
                // //console.log(error);
            });
    }

    // This function will delete a pay adjustment
    removePayAdjustment(RowIndex, PayAdjID) {

        // Now check their pay adjustments to find the pay adjustment
        var PAid = 0;
        while (PAid < this.state.rows[RowIndex].PayAdjustments.length) {
            if (this.state.rows[RowIndex].PayAdjustments[PAid]._id == PayAdjID) { break; }
            PAid++;
        }
        if (PAid == this.state.rows[RowIndex].PayAdjustments.length) {
            alert("An error has occurred. Please contact the IT department.");
            return;
        }

        // We should have a SSDump ID and a pay adjustment ID
        // Time to delete it

        var self = this;
        axios
            .post(`/api/PayAdjust/Delete/${PayAdjID}`)
            .then(function (response) {
                // First, get the rows and pay adjustments
                let SSDumpRows = produce(self.state.rows, draft => {
                    draft[RowIndex].PayAdjustments.splice(PAid, 1);
                });
                // console.log(SSDumpRows,"SSSDUMPS");
                self.setState({ rows: SSDumpRows, PayAdjustmentData: SSDumpRows[RowIndex] });
            })
            .catch(function (error) {
                // //console.log("error");
                // //console.log(error);
            });
    }

    // This function will create detail rows for pay adjustments
    RowDetail(row) {
        let showform = true;
        if (row.row.Hours.length > 0) {
            if (row.row.Hours[0].Status && row.row.Hours[0].Status == "finalized") {
                showform = false;
            }
        }

        return (
            // <PayAdjustmentModal
            //     className="modal"
            //     show={this.state.showpopup}
            //     closepopup={this.HidePopup}
            //     PayAdjustmentData={row.row}
            //     SSDumpID={mongoose.Types.ObjectId(row.row.id)}
            //     RowID={row.row.id}
            //     OrderBranchID={row.row.OrderBranchID}
            //     WeDate={this.props.userSelectedWEDate}
            //     AssNum={row.row.AssNum}
            //     EmpID={row.row.EmpID}
            //     ShowForm={true}
            //     RowIndex={row.row.RowIndex}
            //     PayAdjustInfo={row.row.PayAdjustments}
            //     NewStateInfo={row.row.NewState}
            //     UpdateFunction={this.addPayAdjustment}
            //     RemoveFunction={this.removePayAdjustment}
            //     TeamMates={row.row.FName + " " + row.row.LName}
            // ></PayAdjustmentModal>

            <PayAdjustment
                SSDumpID={mongoose.Types.ObjectId(row.row.id)}
                RowID={row.row.id}
                OrderBranchID={row.row.OrderBranchID}
                WeDate={this.props.userSelectedWEDate}
                AssNum={row.row.AssNum}
                EmpID={row.row.EmpID}
                ShowForm={showform}
                RowIndex={row.row.RowIndex}
                PayAdjustInfo={row.row.PayAdjustments}
                NewStateInfo={row.row.NewState}
                UpdateFunction={this.addPayAdjustment}
                RemoveFunction={this.removePayAdjustment}
                PayAdjustmentData={row.row}
            />

        );
    }
    // ***** ***** ***** ***** ***** ***** ***** *****

    // Restyles the table header group cells
    renderHeaderCell({ style, colSpan, row, column, expanded, classes, children, className, tableRow, tableColumn, onToggle, ...restProps }) {
        return (
            <th colSpan={colSpan} className="groupCell" style={style} {...restProps}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }} >
                    <div style={{
                        width: "100%",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }} >
                        {column.title}
                    </div>
                </div>
            </th>
        );
    }

    // Rewrites the group row cell so we don't have unneccessary code and also the show this company link
    renderGroupCell({ style, colSpan, row, column, expanded, classes, children, className, tableRow, tableColumn, onToggle, ...restProps }) {
        let companyTag = row.value;
        if (children != null) { companyTag = children; }
        var [compID, CompBranchID, compName] = companyTag.split("==");
        const groupToggles = this.state.groupToggles;

        const RowTitleClick = () => onToggle();
        const toggleID = "toggle_icon_" + compID;
        const ShowCompany = () => {
            this.toggleCompanyShowAll(compID);
        };
        let icon = "";
        if (groupToggles[compID]) {
            icon = <i className="material-icons">toggle_on</i>;
        } else {
            icon = <i className="material-icons">toggle_off</i>;
        }
        var showlink = "";
        if (this.props.userSelectedBranch === CompBranchID && this.state.groupShowLinks[compName]) {
            showlink = (
                <div className="CompanyAll" onClick={ShowCompany}>
                    Show All Teammates
                    {icon}
                </div>
            );
        }

        return (
            <Table.Cell colSpan={colSpan} style={style} {...restProps}>
                <span className="CompanyName" onClick={RowTitleClick}>
                    {compName}
                </span>
                {showlink}
            </Table.Cell>
        );
    }

    // This function will update the state with the new hours
    // It does not save to the database
    handleTimeChange(event, rowIndex, hrtype) {
        let rows = this.state.rows.slice();
        if (rows[rowIndex]['Hours'].length === 0) {
            rows[rowIndex]['Hours'].push({});
        }

        //console.log(hrtype);
        //console.log(rows[rowIndex]['Hours'][0]);
        rows[rowIndex]['Hours'][0][hrtype + 'Hours'] = event.target.innerHTML;
        rows[rowIndex]['Hours'][0].Finalized = "1900-01-01";
        this.setState({ rows: rows })
    }

    // This function wraps around the saveHours function and also updates the timestamp
    changeHours(event, row, hrtype) {

        if (document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_" + hrtype + "_" + row.AssNum) != null) {
            document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_" + hrtype + "_" + row.AssNum).removeAttribute("disabled");
            document.getElementById(row.EmpID + "_" + row.FName + "_" + row.LName + "_" + hrtype + "_" + row.AssNum).classList.remove("hours-input-row-change");
        }
        //document.getElementById(row.EmpID + "_" + row.FName + "_" + hrtype).classList.remove("hours-input-row-change");     
        // Save the hours
        //event.preventDefault();
        saveHours(this, event.target, row, hrtype);
        document.getElementById('result').style.display = 'block';
        document.getElementById('result').innerHTML = "Auto Saved @ " + Moment().format("MM/DD/YYYY hh:mm a")
        //this.getWholePageUpdate();
    }

    // This function will toggle visibilities for rows
    toggleCompanyShowAll(toggleCompID) {

        // get state of rows
        let rowsdata = this.state.rows;
        let expandedRows = this.state.expandedRows;
        let groupToggles = this.state.groupToggles;

        // cycle through the rows and see if compID matches
        let icon = false;
        for (var i = 0; i < rowsdata.length; i++) {
            // If the row is under the company and is toggeable, change it's visibility
            if (rowsdata[i].CompID == toggleCompID && rowsdata[i].Toggleable) {
                rowsdata[i].Visible = !rowsdata[i].Visible;
                icon = icon || rowsdata[i].Visible;
                //if ((rowsdata[i].Visible) && (rowsdata[i].PayAdjustments.length > 0))
                //     { expandedRows[i] = i; }
                //else
                //     { delete expandedRows[i]; }
            }
        }

        // Change the icon
        groupToggles[toggleCompID] = !groupToggles[toggleCompID];

        this.setState({
            rows: rowsdata,
            expandedRows: expandedRows,
            showAllTeamates: true,
            groupToggles: groupToggles
        });
    }

    showLoaderFor3Secs = () => {
        this.showLoader();
        var that = this;
        setTimeout(() => {
            that.hideLoader()
        }, 2000);
    }

    showLoader = () => {
        document.getElementById('loader').style.display = "block";
    }

    hideLoader = () => {
        document.getElementById('loader').style.display = "none";
    }


    showLoaderPopUp = () => {
        document.getElementById('loaderpopup').style.display = "block";
    }

    hideLoaderPopUp = () => {
        document.getElementById('loaderpopup').style.display = "none";
    }


    handleSearch = (e) => {
        this.showLoaderFor3Secs();
        var that = this;
        setTimeout(() => {
            that.setState({
                searchValueFinal: document.getElementById("txtSearchBox").value
            });
        }, 100);

    }

    changeSearchValue = (e) => {
        document.getElementById("txtSearchBox").value = e.target.value;
    }

    keyPress = (e) => {
        if (e.keyCode == 13) {
            this.handleSearch();
        }
    }

    // Actual rendering of the table
    render() {

        if (this.state.loading) {
            // Until we have the data pulled in from Mongo display this
            return <div className="donut" />;
        } else {
            // Mongo data loaded, time to make a table
            // Note that DataGrid is picky about the order of things, check documentation for more info
            var {
                columnBands,
                tableColumnExtensions,
                currencyColumns,
                enddateColumns,
                tableGroupRow,
                groups,
                rows,
                expandedRows,
                hideColumns,
                users
            } = this.state;

            const isTimeStamp = this.state.timestamp;
            // const format = !isTimeStamp ? "hide" : "w3-animate-opacity needhand";
            const show = !isTimeStamp
                ? "hide"
                : "date table_list--item w3-animate-opacity";
            //console.log(this.state);

            return (
                <div>
                    {this.renderRedirect()}
                    <div className="showAutoSave">
                        <div className={''} id="result">
                            {/* Auto Saved
                            {"\u00A0"}@ */}
                        </div>
                    </div>

                    <SaveButton
                        thisBranchName={this.props.userSelectedBranch}
                        thisUDFID={this.props.userSelectedUDFID}
                        thisWEDate={this.props.userSelectedWEDate}
                        onClickFunc={this.modalActionShow}
                    />
                    <div className={this.state.SocketClass}>
                        {this.state.SocketMsg}
                        <button className="closeMsgBtn" onClick={this.clearSnatchMsg} >OK</button>
                    </div>

                    <div class="header-search">
                        <input type="text" placeholder="Search.." name="search" id="txtSearchBox" onChange={this.changeSearchValue} onKeyDown={this.keyPress} />
                        <a href="javascript:void(0)" id="btnDoSearch" onClick={this.handleSearch} className="btn btn-primary"><span class="glyphicon glyphicon-search"></span></a>
                    </div>

                    <div id="loader" style={{ 'display': 'none' }}><span class="loader-content">Loading...</span></div>
                    <div id="loaderpopup" style={{ 'display': 'none' }}><span class="loader-content">Saving...</span></div>

                    <Grid cellPadding="0" cellSpacing="0" rows={rows} columns={this.getColumns()}>
                        <CurrencyTypeProvider for={currencyColumns} />
                        <EndDateTypeProvider for={enddateColumns} />
                        <FilteringState
                            filters={[{ columnName: "Visible", value: "True" }]}
                        />
                        {/* <SearchState defaultValue="" /> */}
                        <SearchState value={this.state.searchValueFinal} />
                        <IntegratedFiltering />
                        <GroupingState
                            grouping={[{ columnName: "Company", title: "" }]}
                            defaultExpandedGroups={groups}
                        />
                        <RowDetailState defaultExpandedRowIds={expandedRows} contentComponent={this.RowDetail} />
                        <IntegratedGrouping />
                        <VirtualTable
                            cellPadding="0"
                            cellSpacing="0"
                            height={700}
                            columnExtensions={tableColumnExtensions}
                        />
                        <TableHeaderRow />
                        <TableRowDetail toggleColumnWidth={1} contentComponent={this.RowDetail} />
                        <TableColumnVisibility hiddenColumnNames={hideColumns} />
                        <TableBandHeader
                            cellPadding="0"
                            cellSpacing="0"
                            cellComponent={this.renderHeaderCell}
                            columnBands={columnBands}
                        />
                        <TableGroupRow indentColumnWidth={1}
                            cellComponent={this.renderGroupCell}
                            rowComponent={CustomTableGroupRow}
                            CellProps={tableGroupRow}
                        />
                        <Toolbar />
                        <SearchPanel searchPlaceholder="test" />
                        <SearchBarModification />
                    </Grid>

                    <SnatchModal
                        className="modal"
                        show={this.state.showModal}
                        close={this.modalClose}
                        action={this.modalActionClose}
                        snatchData={this.state.SnatchData}
                        snatchEmails={this.state.SnatchEmails}
                    />

                    <PayAdjustmentModal
                        className="modal"
                        show={this.state.showpopup}
                        closepopup={this.HidePopup}
                        PayAdjustmentData={this.state.PayAdjustmentData}
                        SSDumpID={mongoose.Types.ObjectId(this.state.PayAdjustmentData.id)}
                        RowID={this.state.PayAdjustmentData.id}
                        OrderBranchID={this.state.PayAdjustmentData.OrderBranchID}
                        WeDate={this.props.userSelectedWEDate}
                        AssNum={this.state.PayAdjustmentData.AssNum}
                        EmpID={this.state.PayAdjustmentData.EmpID}
                        ShowForm={true}
                        RowIndex={this.state.PayAdjustmentData.RowIndex}
                        PayAdjustInfo={this.state.PayAdjustmentData.PayAdjustments}
                        NewStateInfo={this.state.PayAdjustmentData.NewState}
                        UpdateFunction={this.addPayAdjustment}
                        RemoveFunction={this.removePayAdjustment}
                        TeamMates={this.state.PayAdjustmentData.FName + " " + this.state.PayAdjustmentData.LName}
                    ></PayAdjustmentModal>
                </div>
            );
        }
    }
}

// For testing
const UserList = (props) => {
    // This is the code to display what users are on the page (useful for debugging sockets)
    // Just add the UserList tag in the render to display the user list
    // <UserList users={users} />
    //console.log(props);
    return (
        <div>{props.users.map((user, i) => <span key={i}>{user}, </span>)}</div>
    );
}