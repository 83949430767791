import config from '../Config/config.json';

const mode = config.settings.mode;

export function GetMode() {
    if (mode === "staging")
        return config.staging.apiroot;
    else if (mode === "live")
        return config.live.apiroot;
        else if (mode === "local")
        return config.local.apiroot;
}