import React, { Component } from "react";
import axios from "axios";
import _ from 'lodash';
export default class componentName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deductions: [],
      value: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillMount() {
    axios
      .get(`/api/Deductions`, { responseType: "json" })
      .then(resp => {
        
        // Resort the data to have display names
        for (var i=0; i<resp.data.length; i++) {
          // Store the codes into the display names where displays are blank
          if (resp.data[i].DisplayName === '') {
               resp.data[i].DisplayName = resp.data[i].Code;
          }
        }
        resp.data.sort(compare);        
        this.setState({
          //deductions: resp.data
          deductions: _.filter(resp.data, {Active: true})
        });
      })
      .catch(console.error);
  }
  
  // Change the state on modifications
  handleChange(event) {
    this.setState({value: event.target.value});
    this.props.deductionCallBack(event.target.value);
  }

  // Renders a select dropdown with a list of deductions for pay adjustments by using the map function over the array of deductions
  render() {
    return (
          <select
               className="codesSelect"
               name="Deductions"
               key={this.state.deductions.id}
          value={this.state.value}
          onChange={this.handleChange}
          >
          <option value="" disabled hidden>Please Select</option>
      {this.state.deductions.map(deduction => (<option value={deduction.Code} key={deduction._id}>{deduction.DisplayName}</option>))}
      </select>
     );
  }
}

// Had to include this to sort the objects by Display name
function compare(a,b) {
  if (a.DisplayName < b.DisplayName)
    return -1;
  if (a.DisplayName > b.DisplayName)
    return 1;
  return 0;
}

