/* Outside libraries/packages/plugins  
 ***********************************/
import React, { Component } from "react";
import Button from "react-bootstrap/lib/Button";
import { GoogleLogout } from "react-google-login";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from "react-router-dom";

/* Components/CSS that we have made       
 ***********************************/
import "./Hero.css";

/* Header class starts here
 ***********************************/
export default class Header extends Component {
  constructor(props, context) {
    super(props, context);
    // //console.log(props);

    this.state = {
      isHovered: false,
      SuperUser: ""
    };
  }
  
  componentDidMount() {
    this.isUnMounted = false;
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }
  componentWillMount() {
     const email = sessionStorage.getItem("email");
     const self = this;
     if (email !== null) {
          axios
            .get(`/api/CheckUserStatus/${email}`)
            .then(resp => {
               if (!self.isUnMounted) {
                    self.setState({
                      SuperUser: resp.data.SuperUser
                    });
               }
            })
            .catch(console.error);
     }
  }


  // functions to show/hide edit button on hover
  handleHover() {
    this.setState({
      isHovered: true
    });
  }

  handleLeave() {
    this.setState({
      isHovered: false
    });
  } // end functions handleHover and handleLeave

  render() {
    const responseGoogle = response => {
      this.setState({
        email: response.w3.U3,
        token: response.w3.Eea
      });
      sessionStorage.setItem("email", response.w3.U3);
      sessionStorage.setItem("token", response.w3.Eea);
      this.loadBranches();
    };

    const logout = () => {
      this.setState({
        email: "",
        token: ""
      });
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("token");
      window.location = "/";
    };

    const logoutBtn = this.state.token ? "hide" : "logoutBtn";
    const email = sessionStorage.getItem("email");

    // render if user has selected branch and we dates
    const btnClass = this.state.isHovered ? "heroEditShow" : "hide";
    const settingsBtnClass = this.state.SuperUser ? "settingsBtn" : "hide";
    // this is if the drawer is opened after a branch/wedate has been chosen before (do not show edit button on hover)
    if (this.props.showEditButtonOnHover && this.props.userSelectedBranch) {
      return (
        <div
          className="headerWithData first"
          onClick={this.handleLeave.bind(this)}
        >
          <div className="heading1">
            {" "}
            {this.props.userSelectedBranchName}{" "}
            <Button className={btnClass}>
              <i className="material-icons" id="create">
                create{" "}
              </i>{" "}
            </Button>{" "}
          </div>{" "}
          <div className="heading2" id="buttonFlex">
            {" "}
            {this.props.userSelectedWEDate}{" "}
          </div>{" "}
        </div>
      );
    } else if (this.props.userSelectedBranch) {
      // this shows if the drawer is not open and a branch/wedate has been chosen
      return (
        <div
          className="headerWithData second"
          onMouseEnter={this.handleHover.bind(this)}
          onMouseLeave={this.handleLeave.bind(this)}
          onClick={this.handleLeave.bind(this)}
        >
          <div className="heading1">
            {" "}
            {this.props.userSelectedBranchName}{" "}
            <Button className={btnClass}>
              <i className="material-icons" id="create">
                create{" "}
              </i>{" "}
            </Button>{" "}
          </div>{" "}
          <div className="heading2" id="buttonFlex" style={{display: this.props.isSummary ? 'none' : ''}}>
            Week Ending: {this.props.userSelectedWEDate}{" "}
          </div>{" "}
          <div className={logoutBtn}>
            <div className={this.state.settingsBtn}>
              {" "}
              <Link to="/settings"> Admin Settings </Link>
            </div>
            <GoogleLogout
              className="formatting"
              buttonText="Log Out"
              onLogoutSuccess={logout}
            />{" "}
          </div>{" "}
        </div>
      );
    } else {
      return <div />;
    }
  }
}
