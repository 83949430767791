import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from "react-router-dom";
export default class SaveButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.state = {
      isLoading: false,
      linkBranchName: this.props.thisBranchName,
      linkUDFID: this.props.thisUDFID,
      linkWEDate: this.props.thisWEDate
    };
  }

  handleClick() {
    this.setState({
      isLoading: true
    });
    // This probably where you would have an `ajax` call
    setTimeout(() => {
      // Completed of async action, set loading state back
      this.setState({ isLoading: false });
    }, 1000);
  }

  render() {
    return (
      <div>
        <div className="dataGrid_CTA">
            <button onClick={this.props.onClickFunc} className="primaryBtn">Next</button>
        </div>
      </div>
    );
  }
}

// <Link to={{ pathname: `/ExceptionsPage/${this.state.linkBranchName}/${this.state.linkUDFID}/${this.state.linkWEDate}` }} >
