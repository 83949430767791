import React, { Component } from "react";
import axios from "axios";

export default class ShowSavedAdjustments extends Component {
  constructor(props) {
    super(props);
    // //console.log(this.props);
    console.log(this.props.rowData,"rowData");
  }
  render() {
    if (!this.isUnMounted) {
      return (
        <div>
          <div className="showSavedContainer">
            {this.props.PayAdjustInfo.map(payAdj => (
              <ShowSavedAdjustmentsTableRow
                RowID={this.props.RowID}
                RowIndex={this.props.RowIndex}
                RemoveFunction={this.props.RemoveFunction}
                key={payAdj._id}
                data={payAdj}
                Hide={this.props.Hide}
              />
            ))}
            {this.props.rowData!=undefined?<span id={"span_" + this.props.rowData.EmpID + "_" + this.props.rowData.FName + "_" + this.props.rowData.LName + "_" + this.props.rowData.AssNum}
              className="socket-span">{this.props.rowData.Hours.length > 0 ?(sessionStorage.getItem("email") != this.props.rowData.Hours[0].EnteredByEmail && this.props.rowData.Hours[0].EnteredByEmail!=undefined  && (typeof this.props.rowData.Hours[0].RTHours != "undefined" || typeof  this.props.rowData.Hours[0].OTHours != "undefined")?"entered by " + this.props.rowData.Hours[0].EnteredByEmail:"") : ""}
            </span>:""}            
          </div>
        </div>
      );
    }
  }
}

class ShowSavedAdjustmentsTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DisplayName: this.props.data.Code
    };
    // //console.log(this.props);
    // //console.log(this.state);
    this.deleteAdjust = this.deleteAdjust.bind(this);
    if (this.props.data.PayAdjType === "Deduction") {
      axios
        .get(`/api/GetDeductions/${this.props.data.Code}/`, {
          responseType: "json"
        })
        .then(resp => {
          if (resp.data[0].DisplayName != "") {
            this.setState({ DisplayName: resp.data[0].DisplayName });
          }
        })
        .catch(console.error);
    } else {
      axios
        .get(`/api/GetWageCodes/${this.props.data.Code}/`, {
          responseType: "json"
        })
        .then(resp => {
          if (resp.data[0].DisplayName != "") {
            this.setState({ DisplayName: resp.data[0].DisplayName });
          } else {
            this.setState({ DisplayName: resp.data[0].WageCodeName });
          }
        })
        .catch(console.error);
    }
  }

  // using this.isUnMounted gets rid of the 'Can't call setState (or forceUpdate) on an unmounted component' error
  componentDidMount() {
    this.isUnMounted = false;
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  deleteAdjust(e) {
    //console.log(this.props);
    this.props.RemoveFunction(this.props.RowIndex, this.props.data._id);
  }

  render() {
    let delbutton = "";
    if (this.props.RemoveFunction !== undefined) {
      delbutton = (
        <div className={"table_label centerText " + this.props.Hide}>
          <div onClick={this.deleteAdjust}>
            <i className="material-icons">highlight_off</i>
          </div>
        </div>
      );
    }
    if (this.props.data.Finalized !== '1900-01-01') { delbutton = ""; }

    ////console.log(this.props);
    if (this.props.data.PayAdjType === "Deduction") {
      return (
        <div className="showAdjRow w3-animate-opacity">
          <div className="table_label">
            {" "}
            <span className="payAdjRow payAdjHeader">
              Deduction:
            </span>
            <span className="payAdjRow">
              {this.state.DisplayName}
            </span>
          </div>
          <div className="table_label">
            Amount:{" "}
            <span className="payAdjText">${this.props.data.Amount}</span>
          </div>
          <div className="table_label">
            <span />
          </div>
          <div className="table_label">
            <span />
          </div>
          <div className="table_label">
            Notes:{" "}
            <span
              className="payAdjText"
              dangerouslySetInnerHTML={{ __html: this.props.data.Reason }}
            />
          </div>
          {delbutton}
        </div>
      );
    } else {
      return (
        <div className="showAdjRow w3-animate-opacity">
          <div className="table_label">
            {" "}
            <div className="payAdjRow payAdjHeader">
              Wage Code:
            </div>
            <span className="payAdjRow">
              {this.state.DisplayName}
            </span>
          </div>
          <div className="table_label">
            {this.props.data.MeasurementValue}:
            <span className="payAdjText"> {this.props.data.Amount}</span>
          </div>
          <div className="table_label">
            Pay Rate:{" "}
            <span className="payAdjText">

              ${this.props.data.Pay.toFixed(2)}

            </span>
          </div>
          <div className="table_label">
            Bill Rate:{" "}
            <span className="payAdjText">
              ${this.props.data.Bill.toFixed(2)}
            </span>
          </div>
          <div className="table_label">
            Notes:{" "}
            <span
              className="payAdjText"
              dangerouslySetInnerHTML={{ __html: this.props.data.Reason }}
            />
          </div>
          {delbutton}
        </div>
      );
    }
  }
}
