import React, {Component} from 'react'
import {Table} from 'react-bootstrap'
import {parse, isAfter} from 'date-fns'
import axios from "axios";
const Moment = require("moment");
const { convertArrayToCSV } = require('convert-array-to-csv');


function SummaryTableRow(props) {
     ////console.log(props);
     const rows = props.summaryData.map((data, index) => {
          const wedate = Moment(data.WeDate).format('YYYY-MM-DD');
          const timestamp = Moment(data.SubmittedTime).format('YYYYMMDDhhmmss');
          return (
               <tr key={index}>
                   <td>{ index > 0 && props.summaryData[index -1].WeDate === data.WeDate ? '' : data.WeDate }</td>
                   <td>{ Moment(new Date(data.SubmittedTime)).format('MM/DD/YY hh:mm') }</td>
                   <td>{ data.FinalBoard ? "Yes" : "No" }</td>
       
                   <td><a style={{textDecoration:'underline'}} onClick={() =>
                         generateReportFile(props.BranchName+"__"+wedate+"__pay-report__"+timestamp+".csv", data._id, props.BranchID, props.UDFID, props.BranchName, wedate) }>Link</a></td>
                   <td><a style={{textDecoration:'underline'}} onClick={() =>
                         generateBatchFile(props.BranchName+"__"+wedate+"__batch-file__"+timestamp+".txt", data._id) }>Link</a></td>
                   <td><a style={{textDecoration:'underline'}} onClick={() =>
                         generatePayAdjFile(props.BranchName+"__"+wedate+"__pay-adjustment__"+timestamp+".csv", data._id, props.BranchID, props.UDFID, props.BranchName, wedate) }>Link</a></td>
               </tr>
          );
     });

    return(
        <tbody>
            {rows}
        </tbody>
    )
}

export default class SummaryTable extends Component {
    render() {
        return (
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Week Ending</th>
                        <th>Submitted</th>
                        <th>Final Board?</th>
                        <th>Proofing Report</th>
                        <th>Batch File</th>
                        <th>Pay Adjustment</th>
                    </tr>
                </thead>
                <SummaryTableRow
                    BranchID={this.props.BranchID}
                    UDFID={this.props.UDFID}
                    BranchName={this.props.BranchName.replace(/\s/g, "_")}
                    summaryData={this.props.summaryData}
                />
            </Table>
        );
    }
}

function generateBatchFile(filename, batchID) {
     axios
          .get(`/api/GetFileData/Batch/${batchID}`)
          .then(resp => {
               // Process the data
              // //console.log(resp);
               let datafile = [];
               resp.data.map((data, i) => {
                 //   //console.log(data);
                    
                    const wedate = Moment(data.WeDate).format('MM/DD/YYYY');
                    //console.log(wedate);
                    if (data.RTHours > 0)
                         { datafile.push([ data.AssNum +";"+ data.EmpID +";2;1;;;"+ wedate +";;;;;;;;"+ padHours(data.RTHours) +";Regular;;;;;;;;;;;;;;;;" ]); }
                    if (data.OTHours > 0)
                         { datafile.push([ data.AssNum +";"+ data.EmpID +";2;1;;;"+ wedate +";;;;;;;;"+ padHours(data.OTHours) +";Overtime;;;;;;;;;;;;;;;;" ]); }
               });
             //  //console.log(datafile)

               // Now to create a link and download the file               
               var element = document.createElement('a');
               element.setAttribute('href', 'data:text/plain;charset=utf-8,' + datafile.join("\n"));
               element.setAttribute('download', filename);
               
               element.style.display = 'none';
               document.body.appendChild(element);
               
               element.click();
               
               document.body.removeChild(element);
               ////console.log("download");
          })
          .catch(console.error);
}

function generatePayAdjFile(filename, batchID, branchID, UDFID, branchName, WeDate) {
     let payadjFile = [["Pay Adjustments"],
                         ["Branch: " + branchName],
                         ["WE Date: " + Moment(WeDate).format("MM/DD/YYYY")],
                         [""],
                         ["Assignment %23","Teammate Name","Deduction Code","Deduction Amount","Wage Code","Units/Hours","Pay Rate","Bill Rate","Notes"]
                    ];
     axios
          .get(`/api/GetFileData/PayAdj/${branchID}/${UDFID}/${batchID}`)
          .then(resp => {
               // Process the data
               resp.data.filter(check => check.PayAdjustments.length > 0).map((empinfo, i) => {
                    empinfo.PayAdjustments.map((adjdata, j) => {
                         if (adjdata.PayAdjType == "Deduction") {
                              // Save a deduction
                              payadjFile.push([ empinfo.AssNum, empinfo.FName+" "+empinfo.LName, adjdata.CodeName, "$"+adjdata.Amount.toFixed(2), "", "", "", "", adjdata.Reason ]);
                         } else {
                              // Save a wage code
                              payadjFile.push([ empinfo.AssNum, empinfo.FName+" "+empinfo.LName, "", "", adjdata.CodeName, adjdata.Amount.toFixed(2),
                                                  "$"+adjdata.Pay.toFixed(2), "$"+adjdata.Bill.toFixed(2), adjdata.Reason ]);
                         }
                    });
               })

               // Now to create a link and download the file               
               var element = document.createElement('a');
               element.setAttribute('href', 'data:text/plain;charset=utf-8,' + convertArrayToCSV(payadjFile));
               element.setAttribute('download', filename);
               
               element.style.display = 'none';
               document.body.appendChild(element);
               
               element.click();
               
               document.body.removeChild(element);
          })
          .catch(console.error);
}

function generateReportFile(filename, batchID, branchID, UDFID, branchName, WeDate) {
     let reportFile = [["Payroll Board"],
                         ["Branch: " + branchName],
                         ["WE Date: " + Moment(WeDate).format("MM/DD/YYYY")],
                         [""]
                    ];
     if (branchID != 0) {
          // Not a WFS branch
          reportFile.push([ "Teammate Name","EEID","Assignment %23","Start Date","End Date","REG Hours","REG Pay Rate","REG Bill Rate","OT Hours","OT Pay Rate","OT Bill Rate" ]);
          
     } else {
          // WFS branch
          reportFile.push([ "Teammate Name","Branch","EEID","Assignment %23","Start Date","End Date","REG Hours","REG Pay Rate","REG Bill Rate","OT Hours","OT Pay Rate","OT Bill Rate" ]);
     }

     axios
          .get(`/api/GetFileData/Report/${branchID}/${UDFID}/${batchID}`)
          .then(resp => {
               // Process the data
               let lastCompany="", TotalRT=0, TotalOT=0, GTotalRT=0, GTotalOT=0;
               resp.data.filter(check => check.Hours.length > 0).map((data, i) => {
                    // First, see if we have a new company (ignoring if blank though)
                    if (data.Company !== lastCompany) {
                         if (lastCompany !== "") {
                              reportFile.push( generateReportFileTotalRow(branchID, "Company Total", TotalRT, TotalOT) );
                              reportFile.push([""]);
                         }
                         lastCompany = data.Company;
                         TotalRT = 0;
                         TotalOT = 0;
                         reportFile.push([ lastCompany.replace('#', '%23') ]);
                    }
                    
                    ////console.log(data);
                    
                    let enddate = "Filled";
                    if (data.EndDate && data.EndDate != "1900-01-01T06:00:00.000Z") {
                         enddate = Moment(data.EndDate).format("MM/DD/YYYY");
                    }
                    
                    if (branchID != 0) {
                         // Not a WFS branch
                         reportFile.push([ data.FName+" "+data.LName, data.EmpID, data.AssNum, Moment(data.StartDate).format("MM/DD/YYYY"), enddate,
                                             data.Hours[0].RTHours.toFixed(2), "$"+data.REGPayRate.toFixed(2), "$"+data.REGBillRate.toFixed(2),
                                             data.Hours[0].OTHours != undefined ? data.Hours[0].OTHours.toFixed(2) : 0,
                                             "$"+data.OTPayRate.toFixed(2), "$" + data.OTBillRate.toFixed(2) ]);
                    } else {
                         // WFS branch
                         reportFile.push([ data.FName+" "+data.LName, data.OrderBranchName, data.EmpID, data.AssNum, Moment(data.StartDate).format("MM/DD/YYYY"),
                                             enddate, data.Hours[0].RTHours.toFixed(2), "$"+data.REGPayRate.toFixed(2), "$"+data.REGBillRate.toFixed(2),
                                             data.Hours[0].OTHours != undefined ? data.Hours[0].OTHours.toFixed(2) : 0,
                                             "$"+data.OTPayRate.toFixed(2), "$" + data.OTBillRate.toFixed(2) ]);
                    }
                    
                    // Increase the totals for the companies and grand totals
                    TotalRT += data.Hours[0].RTHours;
                    TotalOT +=
                    data.Hours[0].OTHours != undefined ? data.Hours[0].OTHours : 0;
                    GTotalRT += data.Hours[0].RTHours;
                    GTotalOT +=
                    data.Hours[0].OTHours != undefined ? data.Hours[0].OTHours : 0;
               });
               reportFile.push( generateReportFileTotalRow(branchID, "Company Total", TotalRT, TotalOT) );
               reportFile.push([ "" ]);
               reportFile.push( generateReportFileTotalRow(branchID, "Branch Total", GTotalRT, GTotalOT) );
               ////console.log(reportFile);
               
               // Now to create a link and download the file               
               var element = document.createElement('a');
               element.setAttribute('href', 'data:text/plain;charset=utf-8,' + convertArrayToCSV(reportFile));
               element.setAttribute('download', filename);
               
               element.style.display = 'none';
               document.body.appendChild(element);
               
               element.click();
               
               document.body.removeChild(element);
          })
          .catch(console.error);
}

function generateReportFileTotalRow(BranchID, Label, TotalRT, TotalOT) {
     var returnData =  ["", "", "", "", Label, TotalRT, "", "", TotalOT ];
     if (BranchID == 0) { returnData.unshift(""); }
     return returnData;
}

function createBatchFile(fileID) {
     ////console.log(fileID);
     return "data:text/plain;charset=utf-8,test.txt";
}

function padHours(num) {
     let hrs = Math.round(num * 100);
     let s = hrs + "";
     while (s.length < 4) { s = "0" + s; }
     return s;
}





