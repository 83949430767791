/* Components/CSS that we have added      
  ***********************************/
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from "react-router-dom";

import {
  DataTypeProvider,
  GroupingState,
  SearchState,
  IntegratedGrouping,
  FilteringState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import { RowDetailState } from "@devexpress/dx-react-grid";
import // State or Local Processing Plugins
"@devexpress/dx-react-grid";
import axios from "axios";
import {
  Grid,
  VirtualTable,
  Table,
  TableBandHeader,
  TableHeaderRow,
  TableRowDetail,
  TableColumnVisibility,
  TableGroupRow,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-bootstrap3";
import {
  // Getter,
  Template,
  TemplatePlaceholder,
  Plugin
} from "@devexpress/dx-react-core";
import TimeReview from "./TimeReview";
import { CSVLink } from 'react-csv';
var PrintTemplate = require ('react-print');
// ********************************************************

export class Summary extends React.Component {
  constructor(props){
    super(props)
    ////console.log(props);
    this.state = {
      NoAccess: false,
    };

    // this.sendPayroll = this.sendPayroll.bind(this);
    const userSelectedBranch = `${props.match.params.branchID}`;
    const userSelectedWEDate = `${props.match.params.WEDate}`;
  }

  componentWillMount() {
    const userSelectedBranch = this.props.match.params.branchID;
    const email = sessionStorage.getItem("email");
    const self = this;
    
    // Check and make sure they should even see this page
     if ((userSelectedBranch !== undefined) && (email !== null)) {
          ////console.log('check for access');
          axios
               .get(`/api/CheckBranchAccess/${email}/${userSelectedBranch}`)
               .then(resp => {
                    ////console.log(resp.data);
                    if (resp.data) {
                         self.setState({ NoAccess:true });
                    }
               })
               .catch(console.error);
     } else {
          ////console.log('check not needed');
     }
  }

  // sendPayroll() {
  //   alert('send payroll button clicked')
  // }
  
  
  
  
  render() {
     //createFile('test.txt', 'my content\n', function (err) {
     //     // file either already exists or is now created (including non existing directories)
     //     //console.log("created");
     //});
     let summaryClass = 'mainContent_card';
     let msgClass = 'hide';
     if (this.state.NoAccess) {
          summaryClass = 'hide';
          msgClass = 'mainContent_card';
     }
     
    return <div>
        <div className="hero_container"  id="react-no-print">
          <Link to={{ pathname: `/ExceptionsPage/${this.props.match.params.branchID}/${this.props.match.params.UDFID}/${this.props.match.params.WEDate}` }}>
            <div className="hero_Nav">
              <i className="material-icons yellow">keyboard_arrow_left</i>
              Back to Exceptions
            </div>
          </Link>
          <div className="heading1">Summary</div>
        </div>

 
        <div className="mainContent" id="print-mount">
          <div className={summaryClass}>
               <TimeReview
                    userSelectedBranch={this.props.match.params.branchID}
                    userSelectedUDFID={this.props.match.params.UDFID}
                    userSelectedWEDate={this.props.match.params.WEDate}
               />
          </div>
          <div className={msgClass}>
               You do not have access to this company.
          </div>
        </div>
        
      </div>;
  }
}
