/* Outside libraries/packages/plugins  
 ***********************************/
import React from "react";

import {
  BrowserRouter as Router,
  Route,
  // Link,
  Switch
  // Redirect
} from "react-router-dom";
import "./App.css";
import "./Print.css";
import Sidebar from "./Sidebar/Sidebar";
import MainContent from "./MainContent/MainContent";
import {
  SettingsPage,
  SettingsWageCodePage,
  SettingsDeductionCodePage
} from "./Settings/SettingsPage";

import { ExceptionsPage } from "./MainContent/ExceptionsPage";

import { Summary } from "./MainContent/Summary";

import { TimeReview } from "./MainContent/TimeReview";
//import LogRocket from 'logrocket';
/* App class starts here
 ***********************************/
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
//    LogRocket.init('jzexde/payroll-board');
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentDidUpdate(prevProps, prevState) {

  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth
    });
  };
  render() {
    // const email = sessionStorage.getItem("email")
    // console.log(email);
    // This is an example script - don't forget to change it!
   

    const el = document.getElementById("root");
    if (this.state.width >= 1224) {
      // Assuming this is standard desktop size
      return (
        <div className="App">
          {/* render components here */}
          <Sidebar />
          <Router>
            <Switch>
              <Route
                path="/"
                exact
                render={props => (
                  <MainContent
                    userEmail={el.getAttribute("data-param")}
                    {...props}
                  />
                )}
              />

              <Route
                path="/ExceptionsPage/:branchID/:UDFID/:WEDate"
                exact
                render={props => (
                  <ExceptionsPage
                    appState={`${this.state.selectedBranchName}`}
                    {...props}
                  />
                )}
              />
              <Route
                path="/Summary/:branchID/:UDFID/:WEDate"
                exact
                render={props => (
                  <Summary
                    appState={`${this.state.selectedBranchName}`}
                    {...props}
                  />
                )}
              />
              <Route path="/settings" exact render={() => <SettingsPage />} />
              <Route
                path="/settings/wagecodes"
                component={SettingsWageCodePage}
              />
              <Route
                path="/settings/deductions"
                component={SettingsDeductionCodePage}
              />
              <Route
                path="/:branchID/:UDFID/:WEDate"
                exact
                render={props => (
                  <MainContent
                    userEmail={el.getAttribute("data-param")}
                    {...props}
                  />
                )}
              />

            </Switch>
          </Router>
        </div>
      );

    } else {
      return (
        <div className="App">
          <Sidebar />
          <div id="non-desktop">
            Oops! Looks like your screen is too small to view this application.{" "}
            <br /> Please come back on a larger screen.
          </div>
        </div>
      );
    }
    // console.log(email);

  }

}

export default App;
