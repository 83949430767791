/* Outside libraries/packages/plugins  
	***********************************/
import React from "react";
import Moment from "moment";
import ReactGA from "react-ga";
ReactGA.initialize("UA-38613491-26");
ReactGA.pageview(window.location.pathname + window.location.search);

/* FilterForm class starts here
	***********************************/
export default class FilterForm extends React.Component {
  constructor(props) {
    super(props);
    // //console.log(this.props);

    // sets variable to use moment JS to formate dates
    let currdate = Moment();

    this.state = {
      branches: [],
      branchID: "",
      UDFID: "",
      branchName: "",
      WEDate: "",
      action: "",
      wedates: [
        {
          key: "x1jsoisjdfkff",
          id: "last-week",
          WEDate: currdate.day(0).format("MM/DD/YYYY")
        },
        {
          key: "x2lkajdfidlsl",
          id: "current-week",
          WEDate: currdate.day(7).format("MM/DD/YYYY")
        },
        {
          key: "x3akjdgffffff",
          id: "next-week",
          WEDate: currdate.day(7).format("MM/DD/YYYY")
        }
      ]
    };
    // statement to declare that this actually means...this
    this.handleBranchIDChange = this.handleBranchIDChange.bind(this);
    this.handleWEDateChange = this.handleWEDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.trackClick = this.trackClick.bind(this);
  }

  handleReset() {
    this.props.callbackFilterFormReset(false);
  }

  // assigns id to selected branch
  handleBranchIDChange(event) {
    this.setState({
      branchID: event.target.id,
      UDFID: event.target.dataset.udfid,
      branchName: event.target.value,
      // branchChecked: event.targt.value
    });
  }

  // assigns id to selected week ending date
  handleWEDateChange(event) {
    this.setState({
      WEDate: event.target.id,
      // WEDateChecked: event.target.value
    });
  }
  
  handleSubmit(event) {
    const { branchName, WEDate } = this.state;
    const errors = [];
    event.preventDefault();

    // make sure both are chosen
    if ((this.state.branchID === "" || this.state.WEDate === "") && !this.state.isSummary) {
      return;
    }

    // create array to send back up to Hero
    let arrayForm = [];
    arrayForm.push({
      open: false,
      branchid: this.state.branchID,
      UDFID: this.state.UDFID,
      branchName: this.state.branchName,
      wedate: this.state.WEDate
    });

    this.props.callbackFilterFormValues(arrayForm);
  }

  trackClick() {
    // //console.log('apply button clicked')
    ReactGA.event({
      category: "Editing",
      action: "Chose Branch and Week Ending Date"
    });
    // //console.log("this.state.action" + " " + this.state.action);
  }

  // passes data from hero down
  componentDidMount() {
    this.setState({
      branches: this.props.branchesFromHero,
      WEDate: this.props.userSelectedWEDate,
      branchID: this.props.userSelectedBranch,
      UDFID: this.props.userSelectedUDFID,
      branchName: this.props.userSelectedBranchName,
      isSummary: this.props.isSummary
    });
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.isSummary !== prevState.isSummary) {
      return {branchID: undefined, branchName: '', udfid: '', WEDate: '', isSummary: nextProps.isSummary}
    }

    return null;
  }

  render() {

    // sets var to check that both input fields have a value selected. Button is initially set to disabled and when isEnabled becomes true, button is active again
    const { branchName, WEDate } = this.state;
    const branchChecked = branchName !== null && branchName !== '';
    const WEDateChecked = this.props.userSelectedWEDate;

    // sets var for className on the apply button. When user selects an option from each input field the className changes.
    const isEnabled = (branchName.length > 0 && WEDate.length > 0) || (branchChecked && WEDateChecked || (this.props.isSummary && branchChecked));
    const btnClass = !isEnabled ? "hide": "primaryBtn w3-animate-opacity needhand";
     if (branchName.length === null) {
       return<div className="noAccess error">
         You do not have permission to view this application. If you
         think you should have access, <br />please make sure you are
         logging in with your Onin email address.
       </div>
     } else {
       return (
         <form onSubmit={this.handleSubmit}>
           <p className="chooseLabel">Select Branch</p>
           <div className="flexContainer">
             {/* map function to display branches attached to logged in user */}
             {/* on checked statement do not use ===, they are not the same type */}
             {this.props.branchesFromHero.map(b => (
               <div className="flexContainer_item" key={b._id}>
                 <input
                   type="radio"
                   value={b.BranchName}
                   id={b.BranchID}
                   name="branches"
                   data-udfid={b.UDFID}
                   onChange={this.handleBranchIDChange}
                   checked={this.state.branchID == b.BranchID ? true : false}
                 />
                 <label className="apply" htmlFor={b.BranchID}>
                   {b.BranchName}
                   {this.state.BranchID == b.BranchID ? true : false}
                 </label>
               </div>
             ))}
           </div>
           <p style={{display: this.props.isSummary ? 'none' : ''}} className="chooseLabel">Select Week Ending Date</p>
           <div style={{display: this.props.isSummary ? 'none' : ''}} className="flexContainer">
             {/* map function to display week ending dates from declared state inside of constructor function*/}
             {/* on checked statement do not use ===, they are not the same type */}
             {this.state.wedates.map((we, index) => (
               <div className="flexContainer_item" key={we.id}>
                 <input
                   name="wedates"
                   type="radio"
                   id={we.WEDate}
                   value={we.id}
                   onChange={this.handleWEDateChange}
                   checked={this.state.WEDate == we.WEDate ? true : false}
                 />
                 <label className="apply" htmlFor={we.WEDate}>
                   {we.WEDate}
                 </label>
               </div>
             ))}
           </div>
           <div className="footer">
             <div className="footer_item">
               <button className={btnClass} onClick={this.trackClick} type="submit" disabled={!isEnabled}>
                 Apply
                 </button>
             </div>
             {this.props.showCancelButton && (
               <div className="footer_item">
                 <button
                   className="secondaryBtn needhand"
                   type="reset"
                   onClick={this.handleReset}
                 >
                   Cancel
                   </button>
               </div>
             )}
           </div>
         </form>
       );
     }

  }
}