import produce from "immer/dist/immer";
import axios from "axios/index";

export default class RequestQueue {
    _requests = [];
    _processing = false;
    constructor() {
        this.push = this.push.bind(this);
        this.pop = this.pop.bind(this);
        this.process = this.process.bind(this);
    }

    push(request, self, hoursMerge) {
        this._requests.push(request);
        if(!this._processing) {
            this.process(self, hoursMerge);
        }
    }

    pop() {
        return this._requests.splice(0, 1);
    }

    process(self, hoursMerge) {
        this._processing = true;
        const request = this.pop()[0];
        const that = this;       
        // if(typeof request.Finalized == "undefined")
        //     request.Finalized = request.WeDate;
        axios.post(`/api/SavedTime/`, request)
            .then(function(response) {

            // Find and update the row
            let teststate = produce(self.state.rows, draft => {
                draft[request.row.RowIndex].Hours[0] = hoursMerge(self.state.rows[request.row.RowIndex].Hours[0],response.data);

                draft[request.row.RowIndex].Visible = true;
                draft[request.row.RowIndex].Toggleable = false;
            });                  
            //debugger;      
            if(request.row.Hours.length<=0){
                var hours = {};
                hours._id = response.data._id;
                var hrtype = request.HoursType;
                if (hrtype === "RT") { hours.RTHours = request.HoursValue }
                if (hrtype === "OT") { hours.OTHours = request.HoursValue }
                if (hrtype === "DT") { hours.DTHours = request.HoursValue }
                if (hrtype === "TT") { hours.TTHours = request.HoursValue }
               // hours.Finalized = response.data.WeDate;
                request.row.Hours.push(hours);
            }
            
            // self.setState({
            //     rows: teststate
            // });

            if(that._requests.length > 0) {
                // check to see if the previous instruction was on the same row as the next instruction
                // if so and the next instruction has no id git it the id returned here.
                if(that._requests[0].row.RowIndex === request.row.RowIndex && that._requests[0].DBID === "") {
                    that._requests[0].DBID = response.data._id;
                }
                that.process(self, hoursMerge);
            } else {
                that._processing = false;
            }
        })
        .catch(function(error) {
            // //console.log("fail");
           // //console.log(error);
            that._processing = false;
        });
    }
}