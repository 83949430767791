/* Components/CSS that we have added      
***********************************/
import React from "react";
import axios from "axios";
import Moment from "moment";
import produce from "immer";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from "react-router-dom";

// ********************************************************

// Had to include this to sort the objects by Display name
function compare(a, b) {
  // First, test by Last Name
  if (a.LName < b.LName) {
    return -1;
  }
  if (a.LName > b.LName) {
    return 1;
  }

  // If we don't have a return from it, test on First Name
  if (a.FName < b.FName) {
    return -1;
  }
  if (a.FName > b.FName) {
    return 1;
  }

  // Finally check the Middle Initial
  if (a.MName < b.MName) {
    return -1;
  }
  if (a.MName > b.MName) {
    return 1;
  }
  return 0;
}

export class ExceptionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleAccept = this.handleAccept.bind(this);
    const userSelectedBranch = `${props.match.params.branchID}`;
    const userSelectedUDFID = `${props.match.params.UDFID}`;
    const userSelectedWEDate = `${props.match.params.WEDate}`;
    
    this.state = {
      NoAccess: false,
      userSelectedBranch: userSelectedBranch,
      userSelectedUDFID: userSelectedUDFID,
      userSelectedWEDate: userSelectedWEDate,
      processed: false,
      DataTable: []
    };
  }
  
  componentWillMount() {
    // //console.log(`exceptionsPage branch: ${props.match.params.branchID}`);
    // //console.log(`exceptionsPage wedate: ${props.match.params.WEDate}`);
    ////console.log(this.state);


    //.get(`/api/SSDump/${this.props.userSelectedBranch}/${this.props.userSelectedUDFID}/${this.props.userSelectedWEDate}`, { responseType: "json" })
    const userSelectedBranch = this.state.userSelectedBranch;
    const userSelectedUDFID = this.state.userSelectedUDFID;
    const userSelectedWEDate = this.state.userSelectedWEDate;
    const email = sessionStorage.getItem("email");
    const self = this;
    
    // Check and make sure they should even see this page
     if ((userSelectedBranch !== undefined) && (email !== null)) {
          ////console.log('check for access');
          axios
               .get(`/api/CheckBranchAccess/${email}/${userSelectedBranch}`)
               .then(resp => {
                   // //console.log(resp.data);
                    if (resp.data) {
                         self.setState({ NoAccess:true });
                    }
               })
               .catch(console.error);
     } else {
          ////console.log('check not needed');
     }

    axios
      .get(`/api/ExceptionRules`)
      .then(ExceptionRules => {
        const rulecount = ExceptionRules.data.length;

        axios
          .get(`/api/SSDumpExceptions/${userSelectedBranch}/${userSelectedUDFID}/${userSelectedWEDate}/${sessionStorage.getItem("email")}`, {
            responseType: "json"
          })
          .then(resp => {
            // We actually need to possibly recalculate some of this stuff
            let tabledata = []; // Temp table to hold the assembled info
            let saveIndex = -1;
            let exceptionsKey = 0;
            let processingcount = 0;
            ////console.log(resp.data);
            resp.data.forEach(function(entry) {
              // First, see if we can skip this person due to lack of time
              if (entry.Hours.length === 0) {
                return;
              }
              // And then if they've already been finalized we can skip them too
              if (entry.Hours[0].Status === "finalized") {
                return;
              }
              
              // Set any hour entries that are blank to 0 for testing purposes
              

            /*Changes Done By Rohin*/
            /* Comment below line to show if someone only entered OT Hours */   
            /*if (entry.Hours[0].RTHours === undefined) { return; }*/
            
              if (entry.Hours[0].RTHours === undefined) { entry.Hours[0].RTHours = 0; }
              if (entry.Hours[0].OTHours === undefined) { entry.Hours[0].OTHours = 0; }
              if (entry.Hours[0].DTHours === undefined) { entry.Hours[0].DTHours = 0; }
              if (entry.Hours[0].TTHours === undefined) { entry.Hours[0].TTHours = 0; }

              ////console.log('test');
              let testEmpID = '';
              if (tabledata.length > 0) {
               testEmpID = tabledata[saveIndex].EmpID;
              }
              
              // Now add the object to the new hours table
              if (testEmpID !== entry.EmpID) {
                // Add new person to table
                saveIndex++;
                tabledata[saveIndex] = {
                  _id: entry._id,
                  FName: entry.FName,
                  MName: entry.MName,
                  LName: entry.LName,
                  EmpID: entry.EmpID,
                  Exceptions: [],
                  ExceptCount: 0,
                  EmployeeIndex: saveIndex,
                  DT_TotalHours: entry.Hours[0].DTHours,
                  OT_TotalHours: entry.Hours[0].OTHours,
                  RT_TotalHours: entry.Hours[0].RTHours,
                  TT_TotalHours: entry.Hours[0].TTHours,
                  Assignments: [
                    {
                      AssNum: entry.AssNum,
                      StartDate: entry.StartDate,
                      EndDate: entry.EndDate,
                      DTBillRate: entry.DTBillRate,
                      OTBillRate: entry.OTBillRate,
                      REGBillRate: entry.REGBillRate,
                      TTBillRate: entry.TTBillRate,
                      DTPayRate: entry.DTPayRate,
                      OTPayRate: entry.OTPayRate,
                      REGPayRate: entry.REGPayRate,
                      TTPayRate: entry.TTPayRate,
                      DTHours: entry.Hours[0].DTHours,
                      OTHours: entry.Hours[0].OTHours,
                      RTHours: entry.Hours[0].RTHours,
                      TTHours: entry.Hours[0].TTHours
                    }
                  ]
                };
                processingcount += rulecount;
                // //console.log('line 113 exception page')
              } else {
                // Add new assignment to person already in table
                tabledata[saveIndex].DT_TotalHours += entry.Hours[0].DTHours;
                tabledata[saveIndex].OT_TotalHours += entry.Hours[0].OTHours;
                tabledata[saveIndex].RT_TotalHours += entry.Hours[0].RTHours;
                tabledata[saveIndex].TT_TotalHours += entry.Hours[0].TTHours;
                tabledata[saveIndex].Assignments.push({
                  AssNum: entry.AssNum,
                  StartDate: entry.StartDate,
                  EndDate: entry.EndDate,
                  DTBillRate: entry.DTBillRate,
                  OTBillRate: entry.OTBillRate,
                  REGBillRate: entry.REGBillRate,
                  TTBillRate: entry.TTBillRate,
                  DTPayRate: entry.DTPayRate,
                  OTPayRate: entry.OTPayRate,
                  REGPayRate: entry.REGPayRate,
                  TTPayRate: entry.TTPayRate,
                  DTHours: entry.Hours[0].DTHours,
                  OTHours: entry.Hours[0].OTHours,
                  RTHours: entry.Hours[0].RTHours,
                  TTHours: entry.Hours[0].TTHours
                });
              }
            });
            

            // Resort and rekey
            this.setState({
              DataTable: tabledata,
            });
            
            // Now cycle through our new data to find exceptions
            for (let tableIndex=0; tableIndex < tabledata.length; tableIndex++) {
              let entry = tabledata[tableIndex];
              ////console.log( " - - - - - - - - - - - - " );
              ////console.log( `Employee ID - ${entry.EmpID}` );
              for (let i = 0; i < ExceptionRules.data.length; i++) {
                // deconstruct rule
                let rule = ExceptionRules.data[i];
                ////console.log( ` - Rule #${rule.RuleID}` );
                // Start failed as true
                let failed = true;
                for (let j = 0; j < rule.SubRules.length; j++) {
                  // deconstruct subrule
                  let subrule = rule.SubRules[j];
                  ////console.log( ` - - SubRule #${subrule.SubRuleID}` );
                  // Get the value to compare
                  let compval = 0;
                  switch (subrule.Compare1) {
                    case "RT":
                      compval = entry.RT_TotalHours;
                      break;
                    case "OT":
                      compval = entry.OT_TotalHours;
                      break;
                    case "RT+OT":
                      compval = entry.RT_TotalHours + entry.OT_TotalHours;
                      break;
                  }
                  let subruleResult = "";
                  switch (subrule.Operand) {
                    case "LT":
                      subruleResult = compval < subrule.Compare2;
                      ////console.log( ` - - - ${compval} < ${subrule.Compare2} ==> ${subruleResult}` );
                      break;
                    case "LE":
                      subruleResult = compval <= subrule.Compare2;
                      ////console.log( ` - - - ${compval} <= ${subrule.Compare2} ==> ${subruleResult}` );
                      break;
                    case "EQ":
                      subruleResult = compval == subrule.Compare2;
                      ////console.log( ` - - - ${compval} == ${subrule.Compare2} ==> ${subruleResult}` );
                      break;
                    case "GT":
                      subruleResult = compval > subrule.Compare2;
                       ////console.log( ` - - - ${compval} > ${subrule.Compare2} ==> ${subruleResult}` );
                      break;
                    case "GE":
                      subruleResult = compval >= subrule.Compare2;
                      ////console.log( ` - - - ${compval} >= ${subrule.Compare2} ==> ${subruleResult}` );
                      break;
                  }
                  // Finally, save to the failed
                  failed = failed && subruleResult;
                }
                ////console.log( ` - - - Rule has failed ==> ${failed}` );

                // Finally chech for failure and if found add to exceptions table
                if (failed) {
                    // Save to DB
                    entry.ExceptCount++;
                    const savedata = {
                      EmpID: entry.EmpID,
                      WeDate: userSelectedWEDate,
                      RuleID: rule.RuleID,
                      RTHours: entry.RT_TotalHours,
                      OTHours: entry.OT_TotalHours,
                      DTHours: entry.DT_TotalHours,
                      TTHours: entry.TT_TotalHours,
                      TableIndex: tableIndex,
                    };
                    ////console.log(savedata);
                    axios
                      .post(`/api/SaveException/`, savedata)
                      .then(function(response) {
                         // Save the exception to the entries table
                         let teststate = produce(self.state.DataTable, draft => {
                              draft[response.data.tableIndex].Exceptions.push({
                                exceptKey: exceptionsKey,
                                _id: response.data._id,
                                RuleID: rule.RuleID,
                                ExceptionText: rule.DisplayText,
                                Priority: rule.Notification[0].Priority,
                                ClassName: rule.Notification[0].ClassName,
                                show: !response.data.Approved,
                                loaded: true,
                              });
                              // Set the exceptions ID
                              let id = draft[response.data.tableIndex].Exceptions.length - 1;
                              draft[response.data.tableIndex].Exceptions[id].ExceptionIndex = id;
                         });

                         self.setState({
                              DataTable: teststate,
                         });
                        
                         exceptionsKey++;
                         processingcount--;
                         ////console.log(processingcount);
                         if (processingcount === 0) {
                              self.setState({
                                   processed: true,
                              });
                         }
                      })
                      .catch(function(error) {
                        // //console.log("fail");
                        // //console.log(error);
                      });
                } else {
                    processingcount--;
                    ////console.log(processingcount);
                    if (processingcount === 0) {
                         this.setState({
                              processed: true,
                         });
                    }
                }
              }
            }
            
               // Test to see if there were no exceptions to begin with
               ////console.log(processingcount);
               if (processingcount === 0) {
                     self.setState({
                          processed: true,
                     });
               }

          })
          .catch(console.error);
      })
      .catch(console.error);
  }
  

  handleAccept(e, EmployeeIndex, ExceptionIndex, ExecID) {
    ////console.log(this.state);
    ////console.log(empID);

    // Time to find the employee and exception indexes
    let saved_emp_i = ""; // Saved index of the employee
    let saved_exec_i = ""; // Saved index of the exception
    
    ////console.log(EmployeeIndex);
    ////console.log(ExceptionIndex);
    ////console.log(this.state.DataTable);

     // Update part of the state    
     let teststate = produce(this.state.DataTable, draft => {
          draft[EmployeeIndex].Exceptions[ExceptionIndex].show = false;
     });

    // Resave to state
    this.setState({
      DataTable: teststate
    });

    // We need update the exception in the database
    const savedata = {
      ExceptionID: ExecID,
      EnteredByEmail: sessionStorage.getItem("email")
    };
    axios
      .post(`/api/ApproveException/`, savedata)
      .then(function(response) {
        ////console.log("saved");
      })
      .catch(function(error) {
       // //console.log("fail");
        ////console.log(error);
      });
  }

  render() {
    // See if all the exceptions are accepted
    let clear = false;
    let processed = this.state.processed;
    this.state.DataTable.forEach(function(EmpInfo) {
      EmpInfo.Exceptions.forEach(function(Except) {
        clear = clear || Except.show;
      });
    });
    ////console.log(clear);

    
    let buttonClass = !clear ? "hero_CTA" : "hide";
    let ExceptionsClass = clear ? "mainContent_card" : "hide";
    let MessageClass = clear ? "hide" : "mainContent_card";
    const LoadingImgClass = processed ? "hide" : "mainContent_card";
    let MessageText = processed ? "You have no exceptions to view right now." : "";
    if (this.state.NoAccess) {
          ////console.log("They shouldn't be here...");
          buttonClass = "hide";
          ExceptionsClass = "hide";
          MessageClass = "mainContent_card";
          MessageText = "You do not have access to this company."
    }
    ////console.log(this.state.DataTable);

    return (
      <div>
        <div className="hero_container">
          <Link to={{ pathname: `/${this.props.match.params.branchID}/${this.props.match.params.UDFID}/${this.props.match.params.WEDate}` }} >
            <div className="hero_Nav">
              <i className="material-icons yellow">keyboard_arrow_left</i>
              Back to Payroll Board
            </div>
          </Link>
          <div className={buttonClass}>
            <Link to={{ pathname: `/Summary/${this.props.match.params.branchID}/${this.props.match.params.UDFID}/${this.props.match.params.WEDate}` }} >
              <button className="primaryBtn">Next</button>
            </Link>
          </div>
          <div className="heading1">Exceptions</div>
        </div>
        
        
        <div className="mainContent">
          <div className={LoadingImgClass} style={{marginTop:'15px'}} >
               <div className="donut" />
          </div>
        
          <div className={ExceptionsClass}>
            <div className="header-row">
              <div className="excep-table-label" />
              <div className="excep-table-label center">ASSIGNMENT INFO</div>
              <div className="excep-table-label center moveover">
                REGULAR TIME
              </div>
              <div className="excep-table-label center moveover">OVERTIME</div>
            </div>
            <div className="header-row-second">
              <div className="excep-table-label left" />
              <div className="excep-table-label center">EEID</div>
              <div className="excep-table-label center">Number</div>
              <div className="excep-table-label center">START</div>
              <div className="excep-table-label center">END</div>
              <div className="excep-table-label">HRS</div>
              <div className="excep-table-label">PAY</div>
              <div className="excep-table-label">BILL</div>
              <div className="excep-table-label">HRS</div>
              <div className="excep-table-label">PAY</div>
              <div className="excep-table-label">BILL</div>
            </div>
            <div>
              {this.state.DataTable.map(empInfo => (
                <ExceptionsEmpRow
                  key={empInfo._id}
                  data={empInfo}
                  BranchID={this.props.match.params.branchID}
                  UDFID={this.props.match.params.UDFID}
                  WeDate={this.props.match.params.WEDate}
                  handleResolve={this.handleResolve}
                  handleAccept={this.handleAccept}
                />
              ))}
            </div>
          </div>
          
          <div className={MessageClass}>
               {MessageText}
          </div>
        </div>
      </div>
    );
  }
}

class ExceptionsEmpRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    ////console.log(this.props);
    let show = false;
    this.props.data.Exceptions.forEach(function(exception) {
      show = show || exception.show;
    });
    ////console.log(this.props);
    
    // Handle the end date
    let DispEndDate = "Filled";
    if (this.props.data.Assignments[0].EndDate == "1900-01-01")
          { DispEndDate = Moment(this.props.data.Assignments[0].EndDate).format("MM/DD/YYYY"); }


    if (!show) {
      return null;
    }
    return (
      <div key={this.props.data._id}>
        <div className="rowItem excep-table-name">
          {this.props.data.FName + " " + this.props.data.LName}
        </div>
        <div className="header-row-second">
          <div className="excep-table-label" />
          <div className="excep-table-label center">
            {this.props.data.EmpID}
          </div>
          <div className="excep-table-label center">
            {this.props.data.Assignments[0].AssNum}
          </div>
          <div className="excep-table-label center">
            {Moment(this.props.data.Assignments[0].StartDate).format(
              "MM/DD/YYYY"
            )}
          </div>
          <div className="excep-table-label center">
               {DispEndDate}
            {}
          </div>
          <div className="excep-table-label">
            {this.props.data.RT_TotalHours}
          </div>
          <div className="excep-table-label">
            ${this.props.data.Assignments[0].REGPayRate.toFixed(2)}
          </div>
          <div className="excep-table-label">
            ${this.props.data.Assignments[0].REGBillRate.toFixed(2)}
          </div>
          <div className="excep-table-label">
            {this.props.data.OT_TotalHours}
          </div>
          <div className="excep-table-label">
            ${this.props.data.Assignments[0].OTPayRate.toFixed(2)}
          </div>
          <div className="excep-table-label">
            ${this.props.data.Assignments[0].OTBillRate.toFixed(2)}
          </div>
        </div>
        <div className="exceptionDetailContainer">
          {this.props.data.Exceptions.map(exceptions => {
               if (exceptions.loaded) {
                    return (
                         <ExceptionsInfoRow
                           data={exceptions}
                           key={exceptions._id}
                           EmpID={this.props.data.EmpID}
                           BranchID={this.props.BranchID}
                           UDFID={this.props.UDFID}
                           WeDate={this.props.WeDate}
                           EmployeeIndex={this.props.data.EmployeeIndex}
                           ExceptionIndex={exceptions.ExceptionIndex}
                           handleAccept={this.props.handleAccept}
                           RTHours={this.props.data.RT_TotalHours}
                           OTHours={this.props.data.OT_TotalHours}
                           DTHours={this.props.data.DT_TotalHours}
                           TTHours={this.props.data.TT_TotalHours}
                         />
                    );
               }
            })}
        </div>
      </div>
    );
  }
}

class ExceptionsInfoRow extends React.Component {
  constructor(props) {
    super(props);
    ////console.log(props);
    this.AcceptClick = this.AcceptClick.bind(this);
  }

  AcceptClick(e) {
    // //console.log(this.props.EmpID);
    this.props.handleAccept(e, this.props.EmployeeIndex, this.props.ExceptionIndex, this.props.data._id);
  }

  render() {
    ////console.log(this.props);
    // if (!this.props.data.show) {
    //   return null;
    // }

    let ExceptionText = this.props.data.ExceptionText;
    switch (this.props.data.RuleID) {
      case 2:
        const totalhours = this.props.OTHours + this.props.RTHours;
        ExceptionText = ExceptionText.replace("__", totalhours);
        break;
    }
    ////console.log(this.props);

    return (
      <div key={this.props.data._id}>
        <div className="exceptionsRow">
          <div className="exceptionText">{ExceptionText}</div>
          <Link
            className="smallSecondaryBtn resolveBtn"
            to={{ pathname: `/${this.props.BranchID}/${this.props.UDFID}/${this.props.WeDate}` }}
          >
            Adjust
          </Link>
          <button
            className="smallPrimaryBtn acceptBtn"
            onClick={this.AcceptClick}
          >
            Accept
          </button>
          <div className="exceptionText" />
        </div>
      </div>
    );
  }
}
