/* Components/CSS that we have added      
  ***********************************/
import React from "react";
import axios from "axios";
import Moment from "moment";
import ReactGA from "react-ga";
import mongoose from "mongoose";
import {
  DataTypeProvider,
  GroupingState,
  SearchState,
  IntegratedGrouping,
  FilteringState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import { RowDetailState } from "@devexpress/dx-react-grid";
import // State or Local Processing Plugins
  "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  Table,
  TableBandHeader,
  TableHeaderRow,
  TableRowDetail,
  TableColumnVisibility,
  TableGroupRow,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-bootstrap3";
import {
  // Getter,
  Template,
  TemplatePlaceholder,
  Plugin,
  TemplateConnector,
  Getter
} from "@devexpress/dx-react-core";
import SendToPayrollModal from "./SendToPayrollModal";
/* Components/CSS that we have made       
  ***********************************/
import ShowSavedAdjustments from "./ShowSavedAdjustments";
// import StorageComponent from "./StorageComponent";
import "./MainContent.css";
import SaveButton from "./SaveButton";
ReactGA.initialize("UA-38613491-26");
ReactGA.pageview(window.location.pathname + window.location.search);

// const SearchMessages = {
//   searchPlaceholder: "Search the teammates..."
// };

// This function formats the value into currency form
const CurrencyFormatter = ({ value }) => {
  if (value === undefined) {
    return "";
  }
  return "$" + value;
};

// The DataTypeProvider that uses the Currency Formatter
const CurrencyTypeProvider = props => {
  return <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />;
};

// This function is used to add a class to the table rows
const CustomTableGroupRow = ({ row, ...restProps }) => {
  ////console.log(row);
  ////console.log(restProps);
  return <Table.Row className="GroupRow" {...restProps} />;
};

const isSummaryRow = tableRow => tableRow.type === "summary";

const tableBodyRowsComputed = ({ tableBodyRows }) => {
  let summaryRowCount = 0;
  let RTHourSum = 0;
  let OTHourSum = 0;
  return tableBodyRows.reduce((acc, row) => {
    let RTHours = "";
    let OTHours = "";

    // Check for defined
    if (row.row === undefined) {
      return acc;
    }

    ////console.log(row.row);
    if (row.row.Hours !== undefined) {
      RTHours = row.row.Hours[0].RTHours;
      if (RTHours == undefined || RTHours == "") {
        RTHours = 0;
      }
      OTHours = row.row.Hours[0].OTHours;
      if (OTHours == undefined || OTHours == "") {
        OTHours = 0;
      }
    }

    if (row.type === "group") {
      if (acc.length && acc[acc.length - 1].type === "data") {
        ////console.log(row.row);
        //let compID = '';
        let [compID] = row.row.key.split("==");
        acc.push({
          type: "summary",
          key: `summary_${acc.length}`,
          compID: compID,
          RTHours: RTHourSum,
          OTHours: OTHourSum
        });
        summaryRowCount++;
        RTHourSum = 0;
        OTHourSum = 0;
      }
    }
    if (row.type === "data") {
      RTHourSum += RTHours;
      OTHourSum += OTHours;
    }
    acc.push(row);

    if (
      row.type === "data" &&
      tableBodyRows.length + summaryRowCount === acc.length
    ) {
      let compID = row.row.CompID;
      acc.push({
        type: "summary",
        key: `summary_${acc.length}`,
        compID: compID,
        RTHours: RTHourSum,
        OTHours: OTHourSum
      });
      summaryRowCount++;
    }
    return acc;
  }, []);
};

class SummaryRow extends React.PureComponent {
  render() {
    return (
      <Plugin>
        <Getter name="tableBodyRows" computed={tableBodyRowsComputed} />
        <Template
          name="tableRow"
          predicate={({ tableRow }) => isSummaryRow(tableRow)}
        >
          {params => (
            <TemplateConnector>
              {({ rows, getTableCellColSpan }) => {
                ////console.log(rows);
                ////console.log(params);
                return (
                  <Table.Row>
                    <Table.Cell colSpan="6" />
                    <Table.Cell colSpan="1">Total</Table.Cell>
                    <Table.Cell colSpan="1" style={{ textAlign: "right" }}>
                      {params.tableRow.RTHours}
                    </Table.Cell>
                    <Table.Cell colSpan="2" />
                    <Table.Cell colSpan="1" style={{ textAlign: "right" }}>
                      {params.tableRow.OTHours}
                    </Table.Cell>
                    <Table.Cell colSpan="2" />
                  </Table.Row>
                );
              }}
            </TemplateConnector>
          )}
        </Template>
      </Plugin>
    );
  }
}

// Our main function and the reason why we're here
export default class TimeReview extends React.Component {
  // Builds the data grid and sets up the table
  constructor(props) {
    super(props);
    this.HidePopup = this.HidePopup.bind(this);
    this.renderGroupCell = this.renderGroupCell.bind(this);
    this.RowDetail = this.RowDetail.bind(this);
    this.finalizeTime = this.finalizeTime.bind(this);
    this.printScreen = this.printScreen.bind(this);
    // Hide columns array
    var tempHideCols = ["FilterColumn"];
    if (this.props.userSelectedBranch != 0) { tempHideCols.push("OrderBranchName"); }
    // This sets the state and defines our table options
    this.state = {
      loading: true,
      BranchName: "",
      expandedRows: [],
      groupShowLinks: [],
      timestamp: "",
      ViewGrid: "",
      ViewFinalizing: "hide",
      ViewNoDateMsg: "hide",
      FinalizeBtnShow: "hide",
      fileMsg: "Files are pending...",
      action: "",
      showpopup: false,
      columns: [
        {
          name: "Company",
          title: "Company",
          getCellValue: row => this.renderCompanyTag(row),
          width: 10
        },
        {
          name: "",
          title: "",
          getCellValue: row => this.renderName(row),
          width: 250
        },
        { name: "OrderBranchName", title: "Branch", width: 100 },
        {
          name: "EmpID",
          title: "EEID",
          width: 100
        },
        {
          name: "AssNum",
          title: "Number",
          width: 100
        },
        {
          name: "StartDate",
          title: "Start",
          getCellValue: row =>
            row.StartDate ? Moment(row.StartDate).format("MM/DD/YYYY") : "",
          width: 125
        },
        {
          name: "EndDate",
          title: "End",
          getCellValue: row =>
            row.EndDate && row.EndDate != "1900-01-01T06:00:00.000Z"
              ? Moment(row.EndDate).format("MM/DD/YYYY")
              : "Filled",
          width: 125
        },
        {
          name: "RTHours",
          title: "Hrs",
          getCellValue: row => this.renderHours(row, "RT"),
          width: 60
        },
        {
          name: "REGPayRate",
          title: "Pay",
          width: 100
        },
        {
          name: "REGBillRate",
          title: "Bill",
          width: 100
        },
        {
          name: "OTHours",
          title: "Hrs",
          getCellValue: row => this.renderHours(row, "OT"),
          width: 60
        },
        {
          name: "OTPayRate",
          title: "Pay",
          width: 100
        },
        {
          name: "OTBillRate",
          title: "Bill",
          width: 100
        },
        {
          name: "FilterColumn",
          title: "FilterColumn",
          getCellValue: row => this.renderFilterColumn(row)
        }
      ],
      columnBands: [
        {
          title: "Assignment Info",
          children: [
            { columnName: "AssNum" },
            { columnName: "StartDate" },
            { columnName: "EndDate" }
          ]
        },
        {
          title: "Regular Time",
          children: [
            { columnName: "RTHours" },
            { columnName: "REGPayRate" },
            { columnName: "REGBillRate" }
          ]
        },
        {
          title: "Overtime",
          children: [
            { columnName: "OTHours" },
            { columnName: "OTPayRate" },
            { columnName: "OTBillRate" }
          ]
        }
      ],
      tableColumnExtensions: [
        { columnName: "Company", editingEnabled: false, width: 10 },
        { columnName: "EmpName", editingEnabled: false, width: 250 },
        { columnName: "OrderBranchName", editingEnabled: false, width: 100 },
        {
          columnName: "EmpID",
          editingEnabled: false,
          width: 100,
          align: "center"
        },
        {
          columnName: "AssNum",
          editingEnabled: false,
          width: 100,
          align: "center"
        },
        {
          columnName: "StartDate",
          editingEnabled: false,
          width: 125,
          align: "center"
        },
        {
          columnName: "EndDate",
          editingEnabled: false,
          width: 125,
          align: "center"
        },
        {
          columnName: "RTHours",
          editingEnabled: true,
          width: 100,
          align: "right"
        },
        {
          columnName: "REGPayRate",
          editingEnabled: false,
          width: 100,
          align: "right"
        },
        {
          columnName: "REGBillRate",
          editingEnabled: false,
          width: 100,
          align: "right"
        },
        {
          columnName: "OTHours",
          editingEnabled: true,
          width: 100,
          align: "right"
        },
        {
          columnName: "OTPayRate",
          editingEnabled: false,
          width: 100,
          align: "right"
        },
        {
          columnName: "OTBillRate",
          editingEnabled: false,
          width: 100,
          align: "right"
        },
        {
          columnName: "columnAdd",
          editingEnabled: false,
          width: 75,
          align: "right"
        }
      ],
      enddateColumns: ["EndDate"],
      currencyColumns: ["REGPayRate", "REGBillRate", "OTPayRate", "OTBillRate"],
      hideColumns: tempHideCols,
      groups: [],
      rows: [],
      finalizedCount: 0,
      finalizedTotal: 0,
      EmailNotSent: true,
      batchFile: [],
      payadjFile: [],
      reportFile: [],
      summary: [],
      batchFileID: '',
      userID: '',
      finalBoard: ''
    };
  }
  printScreen(e) {
    window.print();
  }

  ShowPopup = () => {
    this.setState({ showpopup: true, })
  }
  HidePopup() {
    this.setState({
      showpopup: false,
    });
  }
  finalizeTime(finalBoard) {
    //alert(finalBoard);  
    this.HidePopup();
    //let finalBoard = window.confirm("Is this the final payroll board for this week?\nClick OK to yes, Cancel for no");
    //console.log(finalBoard);
    document.getElementById("btnSendToPayroll").disabled = true;
    ReactGA.event(this.state);
    this.setState({
      ViewGrid: "hide",
      ViewFinalizing: "",
      FinalizeBtnShow: "hide",
      category: 'send',
      action: "Submitting Finalized PayRoll Board",
      finalBoard: finalBoard
    });


    // Create a batch file
    let finalizedCount = this.state.finalizedCount;
    let payAdjCount = this.state.payAdjCount;
    const self = this;

    // First, we need to create an entry on the batch table
    axios.post(`/api/BatchFile/Save`, {
      OrderBranchID: this.props.userSelectedBranch,
      UDFID: this.props.userSelectedUDFID,
      WeDate: this.props.userSelectedWEDate,
      FinalBoard: finalBoard
    }).then(function (batchRes) {
      self.setState({
        batchFileID: batchRes.data._id
      });
      ////console.log(batchRes.data._id);

      // Now that we have a batch file id, time to save
      for (let i = 0; i < self.state.rows.length; i++) {
        const rowdata = self.state.rows[i];

        // Cycle through and create some downloadable files
        if (rowdata.Hours.length !== 0) {
          // Send the finalization
          ////console.log(rowdata.Hours[0]._id);
          axios
            .post(`/api/FinalizeSavedTime`, {
              BatchFile: batchRes.data._id,
              SavedTimeID: rowdata.Hours[0]._id,
              index: i
            })
            .then(function (response) {
              ////console.log("hi");
              ////console.log(response);

              finalizedCount++;
              ////console.log(finalizedCount);
              self.setState({
                finalizedCount: finalizedCount
              });
            })
            .catch(function (error) {
              document.getElementById("btnSendToPayroll").disabled = false;
              ////console.log(rowdata.Hours[0]._id);
              ////console.log(error);
            });

          // Don't forget to update the state
          rowdata.Hours[0].Status = "finalized";
        }

        // Now for pay adjustments
        if (rowdata.PayAdjustments.length > 0) {
          for (let j = 0; j < rowdata.PayAdjustments.length; j++) {
            let payinfo = rowdata.PayAdjustments[j];
            // Send the finalization
            // //console.log(rowdata.Hours[0]._id);
            axios
              .post(`/api/FinalizePayAdjust`, {
                BatchFile: batchRes.data._id,
                PayAdjID: payinfo._id
              })
              .then(function (response) {
                ////console.log("hi");
                // //console.log(response + " " + rowdata.Hours[0]._id);
                finalizedCount++;
                self.setState({
                  finalizedCount: finalizedCount
                });
                ////console.log(this.state);
              })
              .catch(function (error) {
                document.getElementById("btnSendToPayroll").disabled = false;
                ////console.log(error);
              });

            // Don't forget to update the state
            rowdata.PayAdjustments[j].Status = "finalized";
          }
        }
      }
    });
  }

  componentWillMount() {
    // Here we make an axios call to the server to pull the table data
    axios
      .get(`/api/SSDump/${this.props.userSelectedBranch}/${this.props.userSelectedUDFID}/${this.props.userSelectedWEDate}/${sessionStorage.getItem("email")}`, { responseType: "json" })
      .then(resp => {
        // Store the responce in a temporary table for now
        const oldTableData = resp.data;
        let total = this.state.finalizedTotal;
        // //console.log(oldTableData);

        // Make a list of the groups
        var GroupListI = 0, testname;
        var newGroupList = new Array(oldTableData[0].CompID + "==" + oldTableData[0].CompBranchID + "==" + oldTableData[0].Company);

        // Now update the UserSelectedBranch value
        var expandedRows = [];
        var GroupShowLinks = [];
        var removeEntries = [];
        let newTableData = [];
        let summary = [];
        summary['GRAND TOTAL'] = { Teammates: 0, RTHrs: 0, OTHrs: 0 }
        let batchFile = this.state.batchFile.slice();
        let payadjFile = this.state.payadjFile.slice();
        let reportFile = this.state.reportFile.slice();
        let newi = -1;
        let lastCompany = "", TotalRT = 0, TotalOT = 0, GTotalRT = 0, GTotalOT = 0;


        // Cycle through the data from the SSDump and put it in a new table
        for (var i = 0; i < oldTableData.length; i++) {
          let rowdata = oldTableData[i];
          // Check to see if they need to be shown
          if (rowdata.Hours.length > 0) {
            // If they don't have regular time then don't show ANYTHING

            /*Changes Done By Rohin*/
            /* Comment below line to show if someone only entered OT Hours */
            // if (rowdata.Hours[0].RTHours === undefined) { continue; }
            if (rowdata.Hours[0].RTHours === undefined && rowdata.Hours[0].OTHours === undefined) { continue; }
            // We also can skip anyone who is finalized
            if (rowdata.Hours[0].Status === "finalized") { continue; }
            // They passed, add to the new table data
            const wedate = Moment(rowdata.Hours[0].WeDate).format("MM/DD/YYYY");
            newTableData.push(rowdata);
            total++;
            newi++;

            // See if we need to create a summary row for this company
            if (summary[rowdata.Company] === undefined) {
              summary[rowdata.Company] = { Teammates: 0, RTHrs: 0, OTHrs: 0 };
            }
            // Add to the teammate count
            summary[rowdata.Company].Teammates++;
            summary['GRAND TOTAL'].Teammates++;

            // Add to the batch file
            if (rowdata.Hours[0].RTHours > 0) {
              batchFile.push([rowdata.AssNum + ";" + rowdata.EmpID + ";2;1;;;" + wedate + ";;;;;;;;" + this.padHours(rowdata.Hours[0].RTHours) + ";Regular;;;;;;;;;;;;;;;;"]);
              summary[rowdata.Company].RTHrs += rowdata.Hours[0].RTHours;
              summary['GRAND TOTAL'].RTHrs += rowdata.Hours[0].RTHours;
            }
            if (rowdata.Hours[0].OTHours > 0) {
              batchFile.push([rowdata.AssNum + ";" + rowdata.EmpID + ";2;1;;;" + wedate + ";;;;;;;;" + this.padHours(rowdata.Hours[0].OTHours) + ";Overtime;;;;;;;;;;;;;;;;"]);
              summary[rowdata.Company].OTHrs += rowdata.Hours[0].OTHours;
              summary['GRAND TOTAL'].OTHrs += rowdata.Hours[0].OTHours;
            }

            // Add to the report file
            if (lastCompany != rowdata.Company) {
              // If we have a new company then create some new rows
              if (lastCompany != "") {
                reportFile.push(["", "", "", "", "Company Total", TotalRT, "", "", TotalOT]);
                reportFile.push([""]);
              }
              lastCompany = rowdata.Company;
              TotalRT = 0;
              TotalOT = 0;
              reportFile.push([lastCompany]);
            }
            let enddate = "Filled";
            if (rowdata.EndDate && rowdata.EndDate != "1900-01-01T06:00:00.000Z") {
              enddate = Moment(rowdata.EndDate).format("MM/DD/YYYY");
            }
            reportFile.push([rowdata.FName + " " + rowdata.LName, rowdata.EmpID, rowdata.AssNum, Moment(rowdata.StartDate).format("MM/DD/YYYY"), enddate,
            rowdata.Hours[0].RTHours, "$" + rowdata.REGPayRate, "$" + rowdata.REGBillRate,
            rowdata.Hours[0].OTHours != undefined ? rowdata.Hours[0].OTHours : 0,
            "$" + rowdata.OTPayRate, "$" + rowdata.OTBillRate]);
            TotalRT += rowdata.Hours[0].RTHours;
            TotalOT +=
              rowdata.Hours[0].OTHours != undefined ? rowdata.Hours[0].OTHours : 0;
            GTotalRT += rowdata.Hours[0].RTHours;
            GTotalOT +=
              rowdata.Hours[0].OTHours != undefined ? rowdata.Hours[0].OTHours : 0;
          } else {
            // They have no hours, skip 'em
            continue;
          }


          // Check for entry on the group list
          testname = rowdata.CompID + "==" + rowdata.CompBranchID + "==" + rowdata.Company;
          if (newGroupList[GroupListI] !== testname) {
            GroupListI++;
            newGroupList[GroupListI] = testname;
          }

          // check for group show link, if null then add it
          if (GroupShowLinks[rowdata.Company] == null) {
            GroupShowLinks[rowdata.Company] = false;
          }

          // Show any pay adjustments they might have
          if (oldTableData[i].PayAdjustments.length > 0) {
            // Add to the expanded rows list
            expandedRows.push(newi);

            // Now add it to the pay adjustments file
            for (let j = 0; j < rowdata.PayAdjustments.length; j++) {
              total++;
              let payinfo = rowdata.PayAdjustments[j];            
              let newrow = [];
              if (payinfo["PayAdjType"] == "Deduction") {
                // Save a deduction
                newrow = [payinfo.AssNum, rowdata.FName + " " + rowdata.LName, payinfo.CodeName, "$" + payinfo.Amount, "", "", "", "", payinfo.Reason];
              } else {
                // Save a wage code
                ////console.log("pay info code");
                ////console.log(payinfo.Amount);
                newrow = [payinfo.AssNum, rowdata.FName + " " + rowdata.LName, "", "", payinfo.CodeName, payinfo.Amount, "$" + payinfo.Pay, "$" + payinfo.Bill, payinfo.Reason];
              }
              payadjFile.push(newrow);
            }
          }
        }
        //////console.log("total: "+total);


        // If we don't have any data then we can quit early
        if (newTableData.length == 0) {
          this.setState({
            loading: false,
            ViewGrid: "hide",
            ViewNoDateMsg: "",
            FinalizeBtnShow: "hide",
            fileMsg: "",
            summary: summary
          });
        } else {
          // Complete the report
          reportFile.push(["", "", "", "", "Company Total", TotalRT, "", "", TotalOT]);
          reportFile.push([""]);
          reportFile.push(["", "", "", "", "Branch Total", GTotalRT, "", "", GTotalOT]);

          // Once we have all the data we can update the state
          this.setState({
            rows: newTableData,
            groups: newGroupList,
            loading: false,
            expandedRows: expandedRows,
            groupShowLinks: GroupShowLinks,
            finalizedTotal: total,
            batchFile: batchFile,
            payadjFile: payadjFile,
            reportFile: reportFile,
            FinalizeBtnShow: "summary_dataGrid_CTA",
            fileMsg: "Files are ready!",
            summary: summary
          });
          //console.log(summary);
        }
      })
      .catch(console.error);


    // And finally let's get the branch name for later
    axios
      .get(`/api/FindBranch/${this.props.userSelectedBranch}`, {
        responseType: "json"
      })
      .then(resp => {
        //console.log(resp.data[0].BranchName);
        this.setState({
          BranchName: resp.data[0].BranchName
        });
      });

    // And the user id
    var email = sessionStorage.getItem("email");
    axios
      .get(`/api/UsersID/${email}`, { responseType: "json" })
      .then(resp => {
        //console.log(resp);
        this.setState({
          userID: resp.data[0].UserID
        });
      });

  }

  // Generate a company tag
  renderCompanyTag(row) {
    var CompID = "",
      CompBranchID = "",
      Company = "";
    if (row.CompID !== undefined) {
      CompID = row.CompID;
    }
    if (row.CompBranchID !== undefined) {
      CompBranchID = row.CompBranchID;
    }
    if (row.Company !== undefined) {
      Company = row.Company;
    }

    return CompID + "==" + CompBranchID + "==" + Company;
  }

  // Generates the employee name
  renderName(row) {
    var FName = "",
      LName = "";
    if (row.FName !== undefined) {
      FName = row.FName;
    }
    if (row.LName !== undefined) {
      LName = row.LName;
    }

    return FName + " " + LName;
  }

  // Generates an input text field for the table
  renderHours(row, hrtype) {
    // First, get the hour values
    let value = "";
    if (row.Hours !== null) {
      if (row.Hours.length > 0) {
        switch (hrtype) {
          case "RT":
            value = row.Hours[0].RTHours;
            break;
          case "OT":
            ////console.log(row.Hours[0].OTHours)
            if (row.Hours[0].OTHours !== undefined) {
              value = row.Hours[0].OTHours;
            }
            break;
          case "DT":
            if (row.Hours[0].DTHours !== undefined) {
              value = row.Hours[0].DTHours;
            }
            break;
          case "TT":
            if (row.Hours[0].TTHours !== undefined) {
              value = row.Hours[0].TTHours;
            }
            break;
        }
      }
    }

    // This will render the actual input field
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: value
        }}
      />
    );
  }

  // Generates a data field to enable filtering
  renderFilterColumn(row) {
    var count = 0;
    if (row.Hours.length > 0) {
      count++;
    }
    if (row.PayAdjustments.length > 0) {
      count++;
    }
    ////console.log(row);
    return count;
  }

  // Restyles the table header group cells
  renderHeaderCell({
    style,
    colSpan,
    row,
    column,
    expanded,
    classes,
    children,
    className,
    tableRow,
    tableColumn,
    onToggle,
    ...restProps
  }) {
    return (
      <th colSpan={colSpan} className="groupCell" style={style} {...restProps}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {column.title}
          </div>
        </div>
      </th>
    );
  }

  // Rewrites the group row cell so we don't have unneccessary code and also the show this company link
  renderGroupCell({
    style,
    colSpan,
    row,
    column,
    expanded,
    classes,
    children,
    className,
    tableRow,
    tableColumn,
    onToggle,
    ...restProps
  }) {
    var companyTag = row.value;
    if (children != null) {
      companyTag = children;
    }
    var [compID, CompBranchID, compName] = companyTag.split("==");

    return (
      <Table.Cell colSpan={colSpan} style={style} {...restProps}>
        {compName}
      </Table.Cell>
    );
  }

  renderIcon(row) {
    return (
      <span>
        <i className="material-icons needhand">swap_horizontal_circle</i>
      </span>
    );
  }

  RowDetail(row) {
    // //console.log(row.row);

    return (
      <ShowSavedAdjustments
        RowID={row.row.id}
        PayAdjustInfo={row.row.PayAdjustments}
      />
    );
  }

  notoggle(row) {
    return <td />;
  }

  componentDidUpdate() {


    //console.log(this.state);

    if ((this.state.finalizedCount == this.state.finalizedTotal) && (this.state.finalizedTotal != 0) && (this.state.EmailNotSent)) {
      let totalhours = 0;
      let payAdjCount = 0;
      //console.log(totalhours);
      for (let i = 0; i < this.state.rows.length; i++) {
        //console.log( this.state.rows[i].Hours[0] );
        if (this.state.rows[i].Hours[0].RTHours !== undefined) { totalhours = totalhours + parseFloat(this.state.rows[i].Hours[0].RTHours); }
        if (this.state.rows[i].Hours[0].OTHours !== undefined) { totalhours = totalhours + parseFloat(this.state.rows[i].Hours[0].OTHours); }
        //console.log(totalhours);
        if (this.state.rows[i].PayAdjustments.length > 0) {
          payAdjCount++;
        }
      }
      let payAdj = false;
      if (payAdjCount > 0) { payAdj = true; }

      let batchStatus = 3;
      if (this.state.finalBoard) { batchStatus = 10; }

      var batchCaveData = {
        appOrigin: "Payroll",
        submittedBy: this.state.userID,
        branchID: this.props.userSelectedBranch,
        UDFID: this.props.userSelectedUDFID,
        WEDate: Moment(this.props.userSelectedWEDate).format("YYYY-MM-DD"),
        payrollBatchID: this.state.batchFileID,
        payAdjustments: payAdj,
        batchStatus: batchStatus,
        totalHours: totalhours
      };
      axios
        .post(`/api/BatchCave/Save`, batchCaveData)
        .then(function (response) {
          //console.log("saved");
        })
        .catch(function (error) {
          //console.log(error);
        });

      /* 
      //console.log("sending email");
      // Everything should be processed, send the email
      // Build the pay adjustment file
      let payadjFile = [["Pay Adjustments"],
                          ["Branch: " + this.state.BranchName],
                          ["WE Date: " + Moment(this.props.userSelectedWEDate).format("MM/DD/YYYY")],
                          [""],
                          ["Assignment #","Teammate Name","Deduction Code","Deduction Amount","Wage Code","Units/Hours","Pay Rate","Bill Rate","Notes"]
                     ];
      let state_payadjfile = this.state.payadjFile;
      for (let key in state_payadjfile) { payadjFile.push(state_payadjfile[key]); }
      // Build the report file
      let reportFile = [["Payroll Board"],
                          ["Branch: " + this.state.BranchName],
                          ["WE Date: " + Moment(this.props.userSelectedWEDate).format("MM/DD/YYYY")],
                          [""],
                          ["Teammate Name","EEID","Assignment #","Start Date","End Date","REG Hours","REG Pay Rate","REG Bill Rate","OT Hours","OT Pay Rate","OT Bill Rate"]
                     ];
      let state_reportfile = this.state.reportFile;
      for (let key in state_reportfile) { reportFile.push(state_reportfile[key]); }
      
      
      //console.log(payadjFile);
      //console.log(reportFile);
      */

      // Send the email
      const savedata = {
        Branch: this.state.BranchName,
        WEDate: this.props.userSelectedWEDate.replace(/-/g, "/"),
        finalBoard: this.state.finalBoard
      };
      /*
           batchfile: this.state.batchFile,
           payadjust: payadjFile,
           payreport: reportFile
      */
      ////console.log(savedata);

      let self = this;
      axios
        .post(`/api/SendBatchFiles`, savedata)
        .then(function (response) {
          //console.log("sent");
          self.setState({
            EmailNotSent: false
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  }

  padHours(num) {
    let hrs = Math.round(num * 100);
    let s = hrs + "";
    while (s.length < 4) {
      s = "0" + s;
    }
    return s;
  }

  // Actual rendering of the table
  render() {
    ////console.log(this.props);
    ////console.log(this.state.rows);
    if (this.state.loading) {
      // Until we have the data pulled in from Mongo display this
      return <div className="donut" />;
    } else {
      // Mongo data loaded, time to make a table
      // Note that DataGrid is picky about the order of things, check documentation for more info
      var {
        columns,
        columnBands,
        tableColumnExtensions,
        currencyColumns,
        enddateColumns,
        tableGroupRow,
        groups,
        rows,
        hideColumns
      } = this.state;
      ////console.log(this.state);
      const isTimeStamp = this.state.timestamp;
      // const format = !isTimeStamp ? "hide" : "w3-animate-opacity needhand";

      // Progress bar width
      const ProgBar = (
        (this.state.finalizedCount / this.state.finalizedTotal) * 100
      ).toFixed(0);

      let ViewProcessing = "";
      let ViewCompleteMsg = "hide";
      if (this.state.finalizedCount == this.state.finalizedTotal) {
        ViewProcessing = "hide";
        ViewCompleteMsg = "";
      }

      // Display the summary table
      //console.log(this.state.summary);
      let summary = this.state.summary;
      let summaryTable = "<tr><th>Company</td><th>Teammates</th><th>Reg Hours</th><th>OT Hours</th></tr>";
      Object.keys(this.state.summary).map(function (keyname, keyindex) {
        if (keyname == 'GRAND TOTAL') { return false; }
        let test = summary[keyname];
        summaryTable += "<tr><td>" + keyname + "</td><td>" + test.Teammates + "</td><td>" + (test.RTHrs).toFixed(2) + "</td><td>" + (test.OTHrs).toFixed(2) + "</td></tr>";
      })
      summaryTable += "<tr class='GrandTotal'><td>Grand Total</td><td>" + this.state.summary['GRAND TOTAL'].Teammates + "</td>";
      summaryTable += "<td>" + (this.state.summary['GRAND TOTAL'].RTHrs).toFixed(2) + "</td><td>" + (this.state.summary['GRAND TOTAL'].OTHrs).toFixed(2) + "</td></tr>";



      return (
        <div>
          <div className={this.state.FinalizeBtnShow} id="react-no-print">
            <button className="primaryBtn" onClick={() => this.ShowPopup()} id="btnSendToPayroll">
              Send To Payroll
            </button>
            <button className="primaryBtn" onClick={(e) => { this.printScreen(e) }}>
              Print
            </button>
          </div>
          <div className="summary_showAutoSave" id="react-no-print">
            {this.state.fileMsg}
          </div>

          <div className={this.state.ViewGrid}>
            <table className="QuickSummary" dangerouslySetInnerHTML={{ __html: summaryTable }}></table>
            <hr className="SummarySeparator" />
            <div id="summary-table">
              <Grid cellPadding="0" cellSpacing="0" rows={rows} columns={columns}>
                <CurrencyTypeProvider for={currencyColumns} />

                <FilteringState
                  filters={[
                    {
                      columnName: "FilterColumn",
                      operation: "greaterThan",
                      value: 0
                    },
                    {
                      columnName: "Visible",
                      value: "True"
                    }
                  ]}
                />
                <IntegratedFiltering />
                <GroupingState
                  grouping={[{ columnName: "Company", title: "" }]}
                  defaultExpandedGroups={groups}
                />
                <IntegratedGrouping />
                <RowDetailState defaultExpandedRowIds={this.state.expandedRows} />
                <VirtualTable
                  CellPadding="0"
                  CellSpacing="0"
                  height={700}
                  columnExtensions={tableColumnExtensions}
                />
                <TableHeaderRow />
                <TableRowDetail
                  contentComponent={this.RowDetail}
                  toggleCellComponent={this.notoggle}
                />
                <TableColumnVisibility hiddenColumnNames={hideColumns} />
                <TableBandHeader
                  CellPadding="0"
                  CellSpacing="0"
                  cellComponent={this.renderHeaderCell}
                  columnBands={columnBands}
                />
                <TableGroupRow
                  cellComponent={this.renderGroupCell}
                  rowComponent={CustomTableGroupRow}
                  CellProps={tableGroupRow}
                />
                <SummaryRow />
                <Toolbar />
              </Grid>
            </div>
            <SendToPayrollModal
              className="modal"
              show={this.state.showpopup}
              closepopup={this.HidePopup}
              action={this.finalizeTime}
            ></SendToPayrollModal>
          </div>

          <div className={this.state.ViewFinalizing}>
            <div className={ViewProcessing}>
              <div>{ProgBar}% processed, please wait...</div>
            </div>

            <div className={ViewCompleteMsg}>
              Your board has been sent to payroll! You will receive a report
              when the batch has been processed.
            </div>
          </div>

          <div className={this.state.ViewNoDateMsg}>
            There is currently nothing to finalize at this point.
          </div>
        </div>
      );
    }
  }
}

/*
The following probably isn't needed but I'm leaving it in in case we need it later
<GroupRowTemplate />
<GroupCellTemplate />
- Place abover <GroupingState> -
- Place at bottom of <Grid> -
*/
