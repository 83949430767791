import React, { Component } from "react";
import PropTypes from "prop-types";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.checked
    };
    this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
  }

  toggleCheckboxChange() {
    const { handleCheckboxChange } = this.props;
    this.setState(({ isChecked }) => ({ isChecked: !isChecked }));
    handleCheckboxChange();
  }

  render() {
    const label = this.props.label;
    const isChecked = this.state.isChecked;

    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            value={label}
            checked={isChecked}
            onChange={this.toggleCheckboxChange}
          />
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired
};

export default Checkbox;
