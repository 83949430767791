import * as React from "react";
import axios from "axios";
import Checkbox from "./Checkbox";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from "react-router-dom";
import { GoogleLogout } from "react-google-login";
import "./Settings.css";

// The general settings page, reached when they click on the settings link
export class SettingsPage extends React.Component {
  render() {
    // console.log('hello')
    return (
      <div>
        <div className="heroExpandContainer">
          <div className="heroExpand">
	          <div className="logoutBtn">
	            <div className="settingsBtn">
	              <div><a class="formatting" href="/">Home</a></div>
	            </div>
	          </div>
            <div className="heading1">Admin Settings</div>
            <ul className="SettingsLinks">
              <li>
                <Link to="/settings/wagecodes">Wage Codes</Link>
              </li>
              <li>
                <Link to="/settings/deductions">Deductions</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mainContent">

        </div>
      </div>
    );
  }
}

// // // // // // // // // // // // // // // // // // // // // // // // //

// This is the page for displaying the Wage Codes
export class SettingsWageCodePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wageCodes: []
    };
console.log('hello')
    // Pull the Wage Codes collection in
    axios
      .get(`/api/AdminWageCodes`, { responseType: "json" })
      .then(resp => {
        console.log(resp.data);
        this.setState({ wageCodes: resp.data });
      })
      .catch(console.error);
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <div className="heroExpandContainer">
          <div className="heroExpand">
	          <div className="logoutBtn">
	            <div className="settingsBtn">
								<div><a class="formatting" href="/settings">Admin Settings</a></div>         
	              <div><a class="formatting" href="/">Home</a></div>
	            </div>
	          </div>
            <div className="heading1">Admin Settings: Wage Codes</div>
            <table className="SettingsTable">
              <tr>
                <th>Wage Code</th>
                <th>Rate Code</th>
                <th>Measurement</th>
                <th>Display</th>
                <th>Display Name</th>
              </tr>
              {this.state.wageCodes.map(codes => (
                <SettingsWageCodeTableRow data={codes} />
              ))}
            </table>
          </div>
        </div>
      </div>
    );
  }
}

// This prints a table row for the Wage Code page
class SettingsWageCodeTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Checked: this.props.data.Active,
      DisplayName: this.props.data.DisplayName
    };
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    console.log("==========");
    console.log(this.props);
  }

  // Whenever we toggle the display checkbox or change the display name this will activate and save it
  componentDidUpdate() {
    axios
      .post(`/api/Update/WageCodes/`, {
        codeID: this.props.data._id,
        active: this.state.Checked,
        display: this.state.DisplayName
      })
      .then(function(response) {})
      .catch(function(error) {
        console.log(error);
      });
  }

  // This function changes the display checkbox
  toggleCheckbox = () => {
    this.setState({
      Checked: !this.state.Checked
    });
  };

  // This function changes the display name state upon changes
  saveName(test) {
    this.setState({
      DisplayName: test.innerHTML
    });
  }

  render() {
    console.log(this.state);
    return (
      <tr>
        <td>{this.props.data.WageCodeName}</td>
        <td>{this.props.data.RateCodeName}</td>
        <td>{this.props.data.WageMeasurement}</td>
        <td>
          <Checkbox
            label={this.props.data._id}
            handleCheckboxChange={this.toggleCheckbox}
            key={this.props.data._id}
            checked={this.props.data.Active}
          />
        </td>
        <td>
          <div
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #C5CDD7",
              width: "150px",
              textAlign: "left",
              flex: "none"
            }}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
              this.saveName(e.target);
            }}
            dangerouslySetInnerHTML={{ __html: this.state.DisplayName }}
          />
        </td>
      </tr>
    );
  }
}

// // // // // // // // // // // // // // // // // // // // // // // // //

// This is the page for displaying the Deduction Codes
export class SettingsDeductionCodePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deductionCodes: []
    };

    axios
      .get(`/api/Deductions`, { responseType: "json" })
      .then(resp => {
        console.log(resp);
        this.setState({ deductionCodes: resp.data });
      })
      .catch(console.error);
  }

  render() {
    return (
      <div>
        <div className="heroExpandContainer">
          <div className="heroExpand">
	          <div className="logoutBtn">
	            <div className="settingsBtn">
								<div><a class="formatting" href="/settings">Admin Settings</a></div>         
	              <div><a class="formatting" href="/">Home</a></div>
	            </div>
	          </div>
            <div className="heading1">Admin Settings: Deductions</div>
            <table className="SettingsTable">
              <tr>
                <th>Deduction Code</th>
                <th>Display</th>
                <th>Display Name</th>
              </tr>
              {this.state.deductionCodes.map(codes => (
                <SettingsDeductionCodeTableRow data={codes} />
              ))}
            </table>
          </div>
        </div>
      </div>
    );
  }
}

// This prints a table row for the Deduction Code page
class SettingsDeductionCodeTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Checked: this.props.data.Active,
      DisplayName: this.props.data.DisplayName
    };
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    console.log(this.props.data._id);
  }

  // Whenever we toggle the display checkbox or change the display name this will activate and save it
  componentDidUpdate() {
    axios
      .post(`/api/Update/Deductions/`, {
        codeID: this.props.data._id,
        active: this.state.Checked,
        display: this.state.DisplayName
      })
      .then(function(response) {})
      .catch(function(error) {
        console.log(error);
      });
  }

  // This function changes the display checkbox
  toggleCheckbox = () => {
    this.setState({
      Checked: !this.state.Checked
    });
  };

  // This function changes the display name state upon changes
  saveName(test) {
    this.setState({
      DisplayName: test.innerHTML
    });
  }

  render() {
    console.log(this.state);
    return (
      <tr>
        <td>{this.props.data.Code}</td>
        <td>
          <Checkbox
            label={this.props.data._id}
            handleCheckboxChange={this.toggleCheckbox}
            key={this.props.data._id}
            checked={this.props.data.Active}
          />
        </td>
        <td>
          <div
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #C5CDD7",
              width: "150px",
              textAlign: "left",
              flex: "none"
            }}
            contentEditable
            suppressContentEditableWarning
            onBlur={e => {
              this.saveName(e.target);
            }}
            dangerouslySetInnerHTML={{ __html: this.state.DisplayName }}
          />
        </td>
      </tr>
    );
  }
}
