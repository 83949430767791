import React from 'react';
import './SnatchModal.css';
import _ from 'lodash';
const SnatchModal = (props) => {


    var result = _(props.snatchData)
        .groupBy(x => x.Email)
        .map((value, key) =>
            ({
                EmailId: key,

                users: value
            })).value();

    return (
        <div>
            <div className="modal-wrapper"
                style={{
                    transform: props.show ? 'translateY(-480vh)' : 'translateY(0vh)',
                    opacity: props.show ? '1' : '0',
                    top: props.show ? '0' : '-85%',

                }}>
                <div className="modal-heading">
                    <h3>Merge Time</h3>
                    {/* <h3>Entered by {props.snatchEmails}</h3> */}
                    <span className="close-modal-btn" onClick={props.close}>x</span>
                </div>
                <div className="modal-body-snatch snatch-email">
                    <p className="modal-message">Other users at your branch have entered time,<br />do you want to submit them with your board?</p>

                    {/* {props.snatchData.map((data, i) =>
                    <div>
                        <span className="snatch_entered_by_email">Entered by {data.Email}</span>
                        <table>
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>EEID</th>
                                    <th>REG HRS</th>
                                    <th>OT HRS</th>
                                </tr>
                            </thead>
                            <tbody>

                                <SnatchModalTableRow key={i} {...data} />


                            </tbody>
                        </table>
                        </div>
                    )} */}


                    {result.map((data, i) => {
                        return (<div>
                            <span className="snatch_entered_by_email">Entered by {data.EmailId}</span>
                            <table>

                                <thead>

                                    <tr>
                                        <th>NAME</th>
                                        <th>EEID</th>
                                        <th>ASSIGNMENT NUMBER</th>
                                        <th>REG HRS</th>
                                        <th>OT HRS</th>
                                        
                                    </tr>
                                </thead>
                                {data.users.map((newdata, i) =>
                                    <tbody>
                                        <SnatchModalTableRow key={i} {...newdata} />
                                    </tbody>

                                )
                                }
                            </table></div>)
                    }

                    )}

                </div>
                <div className="modal-footer">
                    <div className="modal-footer-items">
                        <button className="cancelBtn-modal" onClick={() => props.action(false)}>No Thanks</button>
                        <button className="primaryBtn-modal" onClick={() => props.action(true)}>Merge and Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SnatchModalTableRow = ({ FName, LName, EmpID, Hours, AssNum }) => (
    <tr><td>{FName} {LName}</td>
        <td>{EmpID}</td>
        <td>{AssNum}</td>
        <td>{Hours[0].RTHours}</td>
        <td>{Hours[0].OTHours}</td>       
    </tr>
)


export default SnatchModal;
