/* Outside libraries/packages/plugins  
	***********************************/
// import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from "react-router-dom";
import Moment from "moment";

/* Components/CSS that we have made       
	***********************************/
import React, { Component } from "react";
import Hero from "../Hero/Hero";

import "./MainContent.css";

/* The main class starts here
	***********************************/
export default class MainContent extends Component {
  constructor(props) {
    super(props);
    // //console.log(`exceptionsPage branch: ${props.match.params.branchID}`);
    // //console.log(`exceptionsPage wedate: ${props.match.params.WEDate}`);
    // //console.log(this.props);
  }

  componentWillReceiveProps(nextProps) {}

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  componentWillUpdate(nextProps, nextState) {}

  componentDidUpdate(prevProps, prevState) {
    
  }

  // using this.isUnMounted gets rid of the 'Can't call setState (or forceUpdate) on an unmounted component' error
  componentDidMount() {
    this.isUnMounted = false;
  }

  componentWillUnmount() {
    this.isUnMounted = true;
  }

  render() {
    if (!this.isUnMounted) {
      let formattedWEDate = "";
      if (this.props.match.params.WEDate) {
        formattedWEDate = Moment(this.props.match.params.WEDate).format(
          "MM/DD/YYYY"
        );
      }
      // //console.log(formattedWEDate);
      return (
          <div>
        <Hero
          WEDate={formattedWEDate}
          UDFID={this.props.match.params.UDFID}
          branch={this.props.match.params.branchID}
        />
          </div>
      );
    }
  }
}

MainContent.propTypes = {};
