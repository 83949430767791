import React, { Component } from "react";
import "./Sidebar.css";
// var to store logo image
var Logo = require('./PayrollBoardLogo.png');

export default class Sidebar extends Component {
    render() {
        return (
            <div className="sidebar" id="react-no-print">
                <div className="sidebar_logo">
                    <a href="/"><img src={Logo} title="payroll board, aww yissssss" /></a>
                </div>
                <div className="sidebar_content">
                </div>
            </div>
        );
    }
}

