import React from "react";
import axios from "axios";

export default class WageCodeSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      wageCodes: [],
      wageCodeValue: ""
    };
    this.handleWageCodeChange = this.handleWageCodeChange.bind(this);
  }
  
  componentWillMount() {
    axios
      .get(`/api/WageCodes`, { responseType: "json" })
      .then(resp => {
        // Resort the data to have display names
        for (var i=0; i<resp.data.length; i++) {
          // Store the codes into the display names where displays are blank
          if (resp.data[i].DisplayName === '') {
               resp.data[i].DisplayName = resp.data[i].WageCodeName;
          }
        }
        resp.data.sort(compare);
        ////console.log(resp);
          
        this.setState({
          wageCodes: resp.data
        });
      })
      .catch(console.error);
  }
  
  
  handleWageCodeChange(event) {
    this.setState({ wageCodeValue: event.target.value });
    axios
      .get(`/api/GetWageCodes/${event.target.value}`)
      .then(resp => {
        //console.log(resp);
        this.setState({ wageMeasurement: resp.data[0].WageMeasurement });
        this.render();
        this.props.wageMeasurementValuesCallBack(this.state.wageCodeValue, this.state.wageMeasurement,resp.data[0].WageCodeName);
        // //console.log(this.props.wageMeasurementValuesCallBack(this.state.wageCodeValue, this.state.wageMeasurement));

      })
      .catch(console.error);
  }
  
  render() {
    return (
      <select
        className="codesSelect"
        name="WageCodes"
        key={this.state.wageCodes.id}
        value={this.state.wageCodeValue}
        onChange={this.handleWageCodeChange}
      >
        <option value="" disabled hidden>Please Select</option>
        {this.state.wageCodes.map(codes => (
          <option value={codes.WageCodeID} key={codes._id}>{codes.DisplayName}</option>
        ))}
      </select>
    );
  }
}


// Had to include this to sort the objects by Display name
function compare(a,b) {
  if (a.DisplayName < b.DisplayName)
    return -1;
  if (a.DisplayName > b.DisplayName)
    return 1;
  return 0;
}



