import React from 'react';
import { Z_FIXED } from 'zlib';
//import './SendToPayrollModal.css';
const SendToPayrollModal = (props) => {
    return (
        <div>
            <div className="modal-wrapper" style={{
                    transform: props.show ? 'translateY(-190vh)' : 'translateY(0vh)',
                    opacity: props.show ? '1' : '0',
                    top: props.show ? '18%' : '-85%',
                    left: props.show ? '6%' : '0%',
                    position:'fixed',

                }}>
                <div className="modal-heading">
                    <h3> </h3>
                    <div className="table_label centerText">
                        <div onClick={props.closepopup} className="close-modal-btn">
                            <i className="material-icons">highlight_off</i>
                        </div>
                    </div>
                    {/* <span className="close-modal-btn" onClick={props.closepopup}>x</span> */}
                </div>
                <div className="modal-body-snatch">
                    <p className="modal-message">Is this the final payroll board for this week?</p>

                </div>
                <div className="modal-footer">
                    <div className="modal-footer-items">
                        <button className="primaryBtn-modal timereview" onClick={() => props.action(true)}>Yes</button>
                        <button className="primaryBtn-modal timereview" onClick={() => props.action(false)}>No</button>
                        <button className="cancelBtn-modal timereview timereview-cancel" onClick={props.closepopup}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default SendToPayrollModal;
