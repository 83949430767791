import React from "react";
// import axios from "axios";
// import mongoose from "mongoose";
import WageCodeSelect from "./WageCodeSelect";
import DeductionSelect from "./DeductionSelect";
import ShowSavedAdjustments from "./ShowSavedAdjustments";
import PayAdjustmentModal from "./PayAdjustmentModal";
import // SortingState,
    // SelectionState,
    // FilteringState,
    // PagingState,
    // GroupingState,
    // RowDetailState,
    // IntegratedFiltering,
    // IntegratedGrouping,
    // IntegratedPaging,
    // IntegratedSorting,
    // IntegratedSelection
    "@devexpress/dx-react-grid";

import {
    Grid,
    VirtualTable,
    Table,
    // TableBandHeader,
    TableHeaderRow
    // TableFilterRow,
    // TableSelection,
    // TableGroupRow,
    // TableRowDetail,
    // GroupingPanel,
    // PagingPanel,
    // DragDropProvider,
    // TableColumnReordering,
    // TableColumnResizing,
    // Toolbar
} from "@devexpress/dx-react-grid-bootstrap3";

export default class PayAdjustment extends React.PureComponent {
    constructor(props) {
        super(props);
        ////console.log(this.props)
        this.renderCodes = this.renderCodes.bind(this);
        this.renderDeduction = this.renderDeduction.bind(this);
        this.renderBillRate = this.renderBillRate.bind(this);
        this.handlePayAdjChange = this.handlePayAdjChange.bind(this);
        this.savePayAdj = this.savePayAdj.bind(this);
        this.savePayAdjustment = this.savePayAdjustment.bind(this);
        this.setDeductionCode = this.setDeductionCode.bind(this);
        this.renderMeasurement = this.renderMeasurement.bind(this);
        this.clearState = this.clearState.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.state = {
            id: "",
            Code: "",
            CodeName: "",
            Amount: "",
            Reason: "",
            PayRate: 0,
            BillRate: 0,
            adjustmentType: "",
            wageMeasurementValue: "",
            measurementValue: ""
        };

    }

    savePayAdj(inputName, inputValue, row) {
        ////console.log('inputValue:' + " " + inputValue)
        ////console.log('inputName:' + " " + inputName)
        switch (inputName) {
            case "amount":
                this.setState((prevState, props) => ({
                    Amount: inputValue
                }));
                break;
            case "notes":
                this.setState((prevState, props) => ({
                    Reason: inputValue
                }));
                break;
            case "payRate":
                this.setState((prevState, props) => ({
                    PayRate: parseFloat(inputValue).toFixed(2)
                }));
                break;
            case "billRate":
                this.setState((prevState, props) => ({
                    BillRate: parseFloat(inputValue).toFixed(2)
                }));
                break;
        }
    }

    handlePayAdjChange(e) {
        this.setState({ adjustmentType: e.target.value });
    }

    // this function renders select drop down for the pay adjustment column
    renderDeduction(row) {
        return (
            <DeductionSelect
                deductionCallBack={this.setDeductionCode}
                value={this.state.Deduction}
            />
        );
    }

    setDeductionCode(deductionCode) {
        this.setState({
            Code: deductionCode,
            CodeName: deductionCode,
        });
    }

    // Callback which takes data I need as a parameter
    renderMeasurement = (Code, wageMeasurementCode, CodeName) => {
        this.setState({
            Code: Code,
            measurementValue: wageMeasurementCode,
            CodeName: CodeName
        });
        //console.log("measurement value" + " " + this.state.measurementValue);
    };

    savePayAdjustment() {
        var errors = "";
        if (this.state.Code == "") {
            errors += "You must select a code\n";
        }
        if (this.state.Amount == "") {
            errors += "You must enter an amount\n";
        }
        if (this.state.adjustmentType == "WageCode") {
            if (this.state.PayRate == "") {
                errors += "You must enter a pay rate\n";
            }
            if (this.state.BillRate == "") {
                errors += "You must enter a bill rate\n";
            }
        }
        if (errors == "") {
            // All is well, let's save
            this.props.UpdateFunction(this.props, this.state);
            this.setState({
                id: "",
                Code: "",
                CodeName: "",
                Amount: "",
                Reason: "",
                PayRate: 0,
                BillRate: 0,
                adjustmentType: "",
                wageMeasurementValue: "",
                measurementValue: ""
            });
        } else {
            // We have issues
            alert(errors);
        }
        ////console.log(this.state);
    }

    clearState(e) {
       
        e.preventDefault();
        this.setState({
            id: "",
            Code: "",
            CodeName: "",
            Amount: "",
            Reason: "",
            PayRate: 0,
            BillRate: 0,
            adjustmentType: "",
            wageMeasurementValue: "",
            measurementValue: ""
        })
    }

    componentDidUpdate() {
        if (this.state.adjustmentType === "Deduction") {
            this.setState({ "wageMeasurementValue": "Amount" });
        }
        ////console.log(this.state);
    }

    // This function renders select drop down for the wage codes column
    renderCodes(row) {
        return (
            <WageCodeSelect
                wageMeasurementValuesCallBack={this.renderMeasurement}
                value={this.state.Code}
            />
        );
    }

    // this function renders an input field for the amount column
    renderAmount(row, hrtype) {
        let value = "";
        return (
            <div>
                <div className="flex">
                    <div
                        className="payAmount"
                        name="PayAdjAmount"
                        id="testform"
                        contentEditable
                        suppressContentEditableWarning
                        value={this.state.Amount}
                        onKeyPress={e => {
                            ////console.log(e.charCode);
                            if (e.charCode === 13) {
                                // Return Key
                                this.savePayAdj("amount", e.target.innerHTML, row);
                                //e.preventDefault();
                            }
                            // Now make sure it's a number / period
                            var regex = new RegExp("[^0-9.]");
                            if (regex.test(e.key)) {
                                // Not a match, halt it
                                e.preventDefault();
                            }
                        }}
                        onBlur={e => {
                            this.savePayAdj("amount", e.target.innerHTML, row);
                        }}
                        dangerouslySetInnerHTML={{ __html: value }}
                    />
                </div>
            </div>
        );
    }

    // this function renders an input field for the amount column
    renderPayRate(row) {
        let value = "";
        return (
            <div>
                <div className="flex">
                    <span className="deductionIcon">$</span>
                    <div
                        className="payAmount"
                        name="payRate"
                        id="payRate"
                        contentEditable
                        suppressContentEditableWarning
                        value={this.state.PayRate}
                        onKeyPress={e => {
                            ////console.log(e.charCode);
                            if (e.charCode === 13) {
                                // Return Key
                                this.savePayAdj("payRate", e.target.innerHTML, row);
                                //e.preventDefault();
                            }
                            // Now make sure it's a number / period
                            var regex = new RegExp("[^0-9.]");
                            if (regex.test(e.key)) {
                                // Not a match, halt it
                                e.preventDefault();
                            }
                        }}
                        onBlur={e => {
                            this.savePayAdj("payRate", e.target.innerHTML, row);
                        }}
                        dangerouslySetInnerHTML={{ __html: value }}
                    />
                </div>
            </div>
        );
    }

    // this function renders an input field for the amount column
    renderBillRate(row) {
        let value = "";
        return (
            <div>
                <div className="flex">
                    <span className="deductionIcon">$</span>
                    <div
                        className="payAmount"
                        name="billRate"
                        id="billRate"
                        contentEditable
                        suppressContentEditableWarning
                        value={this.state.BillRate}
                        onKeyPress={e => {
                            ////console.log(e.charCode);
                            if (e.charCode === 13) {
                                // Return Key
                                this.savePayAdj("billRate", e.target.innerHTML, row);
                                //e.preventDefault();
                            }
                            // Now make sure it's a number / period
                            var regex = new RegExp("[^0-9.]");
                            if (regex.test(e.key)) {
                                // Not a match, halt it
                                e.preventDefault();
                            }
                        }}
                        onBlur={e => {
                            this.savePayAdj("billRate", e.target.innerHTML, row);
                        }}
                        dangerouslySetInnerHTML={{ __html: value }}
                    />
                </div>
            </div>
        );
    }

    // this function renders an input field for the notes column
    renderNotes(row) {
        let notes = " ";
        return (
            <div
                className="notes"
                name="PayAdjNotes"
                contentEditable
                suppressContentEditableWarning
                value={this.state.Reason}
                onBlur={e => {
                    this.savePayAdj("notes", e.target.innerHTML, row);
                }}
                dangerouslySetInnerHTML={{ __html: notes }}
            />
        );
    }

    renderSaveBtn(row) {
        return (
            <div className="flexBtn">
                <div className="smallPrimaryBtn needhand" onClick={this.savePayAdjustment}>
                    Add
        </div>
                <div className="needhand redTxt" onClick={this.clearState}>
                    Cancel
        </div>
            </div>
        );
    }

    renderShowAdjustments() {
        if (this.props.PayAdjustInfo !== undefined) {
            return (
                <ShowSavedAdjustments
                    RowID={this.props.RowID}
                    RowIndex={this.props.RowIndex}
                    PayAdjustInfo={this.props.PayAdjustInfo}
                    RemoveFunction={this.props.RemoveFunction}
                    Hide="hide"
                    rowData={this.props.PayAdjustmentData}
                />
            );
        }
    }
    modalClose() {
        this.setState({
            showModal: false,
        });

    }

    modalActionShow() {
        const self = this;
        return <div className="modal-wrapper">
            <div className="modal-heading">
                <h3>{this.state.adjustmentType} </h3>
                {/* <span className="close-modal-btn" onClick={this.clearState}>x</span> */}
            </div>
            <div className="modal-body">
                {this.state.adjustmentType === "Deduction" ?
                    <div className="payAdjustmentForm">
                        <Grid
                            rows={[
                                {
                                    id: 0
                                }
                            ]}
                            columns={[
                                {
                                    name: "payAdjustment",
                                    title: "Deduction",
                                    getCellValue: row => this.renderDeduction(row)
                                },
                                {
                                    name: "amount",
                                    title: "Deduction Amount",
                                    getCellValue: row => this.renderAmount(row)
                                },
                                {
                                    name: "notes",
                                    title: "Notes",
                                    getCellValue: row => this.renderNotes(row)
                                },
                                {
                                    name: "saveBtn",
                                    title: " ",
                                    getCellValue: row => this.renderSaveBtn(row)
                                }
                            ]}
                        >
                            <Table />
                            <TableHeaderRow />
                        </Grid>
                    </div>
                    :
                    <div className="payAdjustmentForm">
                        <Grid
                            rows={[
                                {
                                    id: 0
                                }
                            ]}
                            columns={[
                                {
                                    name: "codes",
                                    title: "Wage Code",
                                    getCellValue: row => this.renderCodes(row)
                                },
                                {
                                    name: "amount",
                                    title: "Unit/Hrs",
                                    getCellValue: row => this.renderAmount(row)
                                },
                                {
                                    name: "PayRate",
                                    title: "Pay Rate",
                                    getCellValue: row => this.renderPayRate(row)
                                },
                                {
                                    name: "BillRate",
                                    title: "Bill Rate",
                                    getCellValue: row => this.renderBillRate(row)
                                },

                                {
                                    name: "notes",
                                    title: "Notes",
                                    getCellValue: row => this.renderNotes(row)
                                },
                                {
                                    name: "saveBtn",
                                    title: " ",
                                    getCellValue: row => this.renderSaveBtn(row)
                                }
                            ]}
                        >
                            <Table
                                cellPadding="0"
                                cellSpacing="0"
                                columnExtensions={[
                                    { columnName: "codes", editingEnabled: true, width: 220 },
                                    { columnName: "amount", editingEnabled: true, width: 120 },
                                    { columnName: "PayRate", editingEnabled: true, width: 120 },
                                    { columnName: "BillRate", editingEnabled: true, width: 120 },
                                    { columnName: "notes", editingEnabled: true, width: 230 },
                                    { columnName: "saveBtn", editingEnabled: true, width: 220 }
                                ]}
                            />

                            <TableHeaderRow />
                        </Grid>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-items">
                    {/* <button className="cancelBtn-modal" onClick={""}>No Thanks</button>
                    <button className="primaryBtn-modal" onClick={""}>Merge and Submit</button> */}
                </div>
            </div>
        </div>
    }
    modalActionClose() {
        this.setState({
            showModal: false
        });
    }
    render() {        
        if (this.state.adjustmentType === "") {            
            const formClass = (this.props.ShowForm) ? "" : "hide";
            const finalizedClass = (this.props.ShowForm) ? "hide" : "selectPay";            
            return (
                <div>
                    {this.renderShowAdjustments()}
                    {/* <div className={formClass}>
                        <div className="selectPayHeader">Add Pay Adjustment</div>
                        <select
                            className="selectPay"
                            value={this.state.adjustmentType}
                            onChange={this.handlePayAdjChange}
                        >
                            <option>Select type</option>
                            <option value="Deduction">Deduction</option>
                            <option value="WageCode">Wage Code</option>
                        </select>
                    </div>
                    <div className={finalizedClass}>
                        This time has been finalized. No more pay adjustments can be submitted.
          </div> */}

                    {/* <div className={formClass}>

                        <div className="modal-wrapper">
                            <div className="modal-heading">
                                <h3>{this.state.adjustmentType} </h3>
                                <span className="close-modal-btn" onClick={this.clearState}>x</span>
                            </div>
                            <div className="modal-body">

                                <div className="selectPayHeader">Add Pay Adjustment</div>




                                <select
                                    className="selectPay"
                                    value={this.state.adjustmentType}
                                    onChange={this.handlePayAdjChange}
                                >
                                    <option>Select type</option>
                                    <option value="Deduction">Deduction</option>
                                    <option value="WageCode">Wage Code</option>
                                </select>
                            </div>

                        </div>

                    </div> */}

                    <div className={finalizedClass}>
                    
                    <i> finalized at {this.props.PayAdjustmentData.Hours.length>0?new Date(this.props.PayAdjustmentData.Hours[0].Finalized).toLocaleTimeString('en-US'):''} on {this.props.PayAdjustmentData.Hours.length>0?new Date(this.props.PayAdjustmentData.Hours[0].Finalized).toLocaleDateString():""} by {this.props.PayAdjustmentData.Hours.length>0?this.props.PayAdjustmentData.Hours[0].EnteredByEmail:""}</i>
                    {/* finalized at {this.props.PayAdjustmentData.length!=undefined?new Date(this.props.PayAdjustmentData.Hours[0].Finalized).toLocaleTimeString('en-US'):''} on {this.props.PayAdjustmentData.length!=undefined?new Date(this.props.PayAdjustmentData.Hours[0].Finalized).toLocaleDateString():""}  by {this.props.PayAdjustmentData.Hours[0].EnteredByEmail} */}
                    
                        {/* This time has been finalized. No more pay adjustments can be submitted. */}
          </div>

                </div>
            );
        } else if (this.state.adjustmentType === "Deduction") {
            return (
                <div>

                    {this.renderShowAdjustments()}
                    {this.modalActionShow()}
                 

                </div>
            );
        } else {
            return (
                <div>
                    {this.renderShowAdjustments()}
                    {this.modalActionShow()}
                </div>
            );
        }
    }
}
